import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FcApproval, FcAssistant, FcBusinessman, FcPlus } from 'react-icons/fc';
import { MdError } from 'react-icons/md';
import { RiRefreshLine } from 'react-icons/ri';
import { Button, Col, Form, Label, Row } from 'reactstrap';
import UserAccountNav from '../../components/Header/UserAccountNav';
import {
  EmptyResult,
  ModalLoader
} from '../../components/misc';
import Enums from "../../constants/enums";
import AccountContext from "../../contexts/AccountContext";
import ActionContext from "../../contexts/ActionContext";
import ModalContext from "../../contexts/ModalContext";
import { getEntry, NetworkErrorHandler } from '../../utils/dataGenerator';

const { BroadcastChannel } = require('broadcast-channel');
const channel = new BroadcastChannel(Enums?.BROADCAST_CHANNELS?.ACCOUNTS);

const mql = window.matchMedia(`(min-width: 800px)`);
const _ = require('lodash');

const AdminDashboard = props => {
  const accountContext = React.useContext(AccountContext);
  const actionContext = React.useContext(ActionContext);
  const modalContext = React.useContext(ModalContext);
  const [sidebarDocked, setSidebarDocked] = React.useState(true);
  const [accounts, setAccounts] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [state, setState] = React.useState({
    isFetching: true,
  });

  const mediaQueryChanged = () => {
    setSidebarDocked(mql.matches);
  };

  React.useEffect(() => {
    mql.addListener(mediaQueryChanged);
    accountContext.getUser(user => {
      if (!user) {
       setTimeout(() => {
          (window.location = '/');
        }, 3000);
      } else {
        setUser(user);
      }
      getUserAccounts();
    });
  }, []);

  React.useEffect(() => {
    channel.onmessage = res => {
      try {
        getUserAccounts();
      } catch (error) {
        console.error(error);
      }
    };
  }, []);

  const getUserAccounts = () => {
    getEntry(`users?$limit=${Math.pow(10, 5)}`, (res, err) => {
      if (!err) {
        const response = res.data;
        setAccounts(response.users);
        setState({ ...state, isFetching: false });
      } else {
        actionContext.showNotification(
          <div className="align-center">
            <MdError style={{ fontSize: 20, color: 'red' }} />
            &nbsp;{NetworkErrorHandler(err)}
          </div>,
          { type: 'error' },
        );
      }
    });
  };

  return (
    <>
      <UserAccountNav current="dashboard" sidebarDocked={sidebarDocked}>
        {state.isFetching ? (
          <ModalLoader open={state.isFetching} />
        ) : (
          <div className="mt-0">
            <div
              style={{
                minHeight: '100vh',
                paddingTop: sidebarDocked ? 50 : 100,
              }}
            >
              <div className="p-3 p-md-4">
                <Row>
                  <Col md={8}>
                    <h4 className="orange-text title-text">Dashboard</h4>
                  </Col>
                  <Col md={4} className="align-right">
                    <Button
                      color="dark"
                      className="clickable --with-highlight"
                      onClick={() => modalContext.toggleAccSideBar()}
                    >
                      <FcPlus style={{ fontSize: 20 }} />
                      &nbsp;Add Account
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row>
                {_.isEmpty(accounts) ? (
                  <Col xs={12}>
                    <EmptyResult
                      text={
                        <>
                          <span>
                            There are currently no accounts at the moment
                          </span>
                          <br />
                          <Button
                            color="dark"
                            className="clickable --with-highlight mt-5"
                            onClick={() => {
                              setState({ ...state, isFetching: true });
                              getUserAccounts();
                            }}
                          >
                            <RiRefreshLine style={{ fontSize: 20 }} />
                            &nbsp;Refresh
                          </Button>
                        </>
                      }
                    />
                  </Col>
                ) : (
                  _.map(accounts, function (user, index) {
                    return (
                      <Col
                        md={3}
                        key={index}
                        className={`shadow admin-card bg-grey p-0 clickable mt-4 mb-2 mx-4`}
                      >
                        <Row>
                          <Col xs={3} className="aligner-container p-2">
                            <div className="thumbnail-xs mx-auto aligner-container shadow-lg">
                              <span className="white-text">
                                {user.first_name.substr(0, 1)}
                              </span>
                            </div>
                          </Col>
                          <Col xs={9} className="p-2">
                            <Form>
                              <Label>Name</Label>
                              <p className="stretched-text-xs capitalize my-0">{`${user.first_name
                                } ${user.last_name}`}</p>
                              <Label>Username</Label>
                              <p className="stretched-text-xs my-0">
                                {user.username}&nbsp;
                                {user.type == Enums.ACCOUNT_TYPES.ROOT ? (
                                  <FcApproval
                                    style={{ fontSize: 20 }}
                                    title="Root"
                                  />
                                ) : user.type == Enums.ACCOUNT_TYPES.AO ? (
                                  <FcAssistant
                                    style={{ fontSize: 20 }}
                                    title="Account Officer"
                                  />
                                ) : (
                                  <FcBusinessman
                                    style={{ fontSize: 20 }}
                                    title="Account Holder"
                                  />
                                )}
                              </p>
                            </Form>
                          </Col>
                        </Row>
                        <div
                          className="bg-dark py-2 px-3 align-right clickable --with-highlight"
                          onClick={() => modalContext.openAccount(user)}
                        >
                          <span
                            className="white-text stretched-text-xs"
                            style={{ fontSize: 13 }}
                          >
                            More Info&nbsp;
                            <BsArrowRight
                              className="white-text"
                              style={{ fontSize: 20 }}
                            />
                          </span>
                        </div>
                      </Col>
                    );
                  })
                )}
              </Row>
            </div>
          </div>
        )}
      </UserAccountNav>
    </>
  );
};

export default AdminDashboard;
