import React from 'react';
import { Col, Row } from "reactstrap";
import HandymanIcon from '@mui/icons-material/Handyman';
import { DetailPageWrapper } from "../components/styledComponents/MainStyles";
import { Typography } from "@mui/material";

const NotFoundPage = () => {

    return (
        <DetailPageWrapper style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Row>
                <Col md={6} className="mx-auto col-10 p-md-4" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: "10em", color: "theme.text", fontWeight: "bolder" }}>404</Typography>
                    <Typography component={"h1"} sx={{ fontSize: "2em", color: "theme.text", fontWeight: 400, margin: 0 }}><strong>Looks Like you found a void</strong></Typography>
                    <Typography component={"h4"} sx={{ fontSize: "1.5em", marginTop: "10px", color: "theme.text", fontWeight: 400 }}>The page which you&apos;re trying to reach doesn&apos;t exist</Typography>
                </Col>
                <Col md={4} className={"mx-auto"}>
                    <HandymanIcon style={{ fontSize: "25rem" }} />
                </Col>
            </Row>
        </DetailPageWrapper>
    )
}

export default NotFoundPage;