import { Box } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useContext, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { MdCheckCircle, MdError } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Switch from 'react-switch';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap';
import { Loader, OTPInputModal, TransactionSuccess } from '../../../components/misc';
import Enums from "../../../constants/enums";
import AccountContext from "../../../contexts/AccountContext";
import ActionContext from "../../../contexts/ActionContext";
import { createEntry, NetworkErrorHandler } from '../../../utils/dataGenerator';

const _ = require('lodash');

const OthersTransfer = props => {
  const actionContext = useContext(ActionContext);
  const accountContext = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [showOTP, toggleOTP] = useState(false);
  const [banks, setBanks] = useState([]);
  const initPayload = {
    bank: '',
    accountNumber: '',
    accountName: '',
    amount: null,
    bic: '',
    addBeneficiary: false,
    transaction_source: 'EXTERNAL_BANKS',
    currency: 'USD',
  };
  const [payload, setPayload] = useState(initPayload);
  const [state, setState] = useState({
    isLoading: false,
    isSuccess: false,
  });

  React.useEffect(() => {
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setPayload({ ...payload, currency: user.displayCurrency || 'USD' });
      setBanks(
        _.map(_.uniq(Enums.BANKS[user.displayCurrency || 'USD']), function (bank, index) {
          return { label: bank, id: index };
        }),
      );
    });
  }, []);

  const validate = e => {
    try {
      e.preventDefault();
      if (!payload.bank) throw Error('Please select a Receiving Bank');
      if (!payload.accountNumber)
        throw Error('Please enter a valid account number');
        if (!payload.bic)
          throw Error('Please enter a valid SWIFT BIC');
      if (!payload.accountName)
        throw Error('Please enter a valid account holder name');
      if (payload.amount <= 0) throw Error('How much do you intend to send?');
      if (!payload.description) throw Error('Please enter a description');
      toggleOTP(true);
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{NetworkErrorHandler(error)}
        </div>,
        { type: 'error' },
      );
      setError(NetworkErrorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  const startTransfer = () => {
    setState({ ...state, isLoading: true });
    createEntry(`transactions/transfer`, payload, (res, err) => {
      if (!err) {
        const response = res.data;
        actionContext.showNotification(
          <div className="align-center">
            <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
            &nbsp;Transfer Successful
          </div>,
          { type: 'success' },
        );
        setState({ ...state, isLoading: false, isSuccess: true });
      } else {
        actionContext.showNotification(
          <div className="align-center">
            <MdError style={{ fontSize: 20, color: 'red' }} />
            &nbsp;{NetworkErrorHandler(err)}
          </div>,
          { type: 'error' },
        );
        setError(NetworkErrorHandler(err));
        setTimeout(() => {
          setError(null);
        }, 1500);
        setState({ ...state, isLoading: false });
      }
    });
  };

  return (
    // <ReactWOW animation="fadeInRight" duration="0.5s" /* delay="0.5s"*/>
    <>
      {showOTP ? (
        <OTPInputModal
          open={showOTP}
          toggle={() => toggleOTP(false)}
          successCallback={startTransfer}
        />
      ) : (
        <div />
      )}
      <div className="bg-white shadow p-3 p-md-5">
        <h5 className="orange-text mb-0 stretched-text-sm">
          Transfer to Others
        </h5>
        {state.isSuccess ? (
          <TransactionSuccess
            data={payload}
            dismiss={() => {
              setPayload(initPayload);
              setState({ ...state, isSuccess: false });
            }}
          />
        ) : (
          <Form onSubmit={!state.isLoading && validate}>
            <Row className="mt-4">
              <Col md={12}>
                <FormGroup>
                  <Label>Destination Bank</Label>
                  <Select
                    isDisabled={state.isLoading}
                    options={banks}
                    className="capitalize"
                    onChange={val =>
                      setPayload({ ...payload, bank: val.label })
                    }
                    isSearchable={true}
                    placeholder={'Select Destination Bank'}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Account No. / IBAN</Label>
                  <Input
                    type="text"
                    value={payload.accountNumber}
                    disabled={state.isLoading}
                    onChange={e =>
                      setPayload({ ...payload, accountNumber: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>SWIFT BIC</Label>
                  <Input
                    type="text"
                    value={payload.bic}
                    disabled={state.isLoading}
                    onChange={e =>
                      setPayload({ ...payload, bic: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Account Holder&apos;s Name</Label>
                  <Input
                    type="text"
                    value={payload.accountName}
                    onChange={e =>
                      setPayload({ ...payload, accountName: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Amount</Label>
                  <InputGroup>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#f3f3f3", width: "30px" }}>
                      {getSymbolFromCurrency(payload.currency)}
                    </Box>
                    <NumberFormat
                      isDisabled={state.isLoading}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      placeholder="0.00"
                      value={payload.amount}
                      displayType={'input'}
                      className="form-control font-weight-medium align-right stretched-text-xs"
                      onChange={v =>
                        setPayload({
                          ...payload,
                          amount: parseFloat(v.target.value),
                        })
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Narration</Label>
                  <Input
                    type="textarea"
                    className="stretched-text-xs"
                    value={payload.description}
                    isDisabled={state.isLoading}
                    onChange={e =>
                      setPayload({
                        ...payload,
                        description: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6} className="aligner-container">
                <Label>Save Beneficiary</Label>
                <span className="pull-right mx-3">
                  <Switch
                    disabled={state.isLoading}
                    onChange={v =>
                      setPayload({ ...payload, addBeneficiary: v })
                    }
                    checked={payload.addBeneficiary}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={16}
                    width={35}
                    handleDiameter={20}
                    onHandleColor={'#2688c9'}
                    offHandleColor={'#d8d8d8'}
                    onColor={'#ffdfd5'}
                    offColor={'#b4b3b3'}
                  />
                </span>
              </Col>
              {!state.isLoading && (
                <Col md={12} className="align-right">
                  <Button
                    type="submit"
                    color="dark"
                    className={`bg-dark white-text clickable --with-highlight ${error ? 'animated shake' : ''}`}
                  >
                    Proceed&nbsp;
                    <BsArrowRight
                      style={{
                        fontSize: 20,
                      }}
                    />
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        )}
        {state.isLoading && <Loader />}
      </div>
      {/* </ReactWOW> */}
    </>
  );
};

export default OthersTransfer;
