import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import remarkGfm from "remark-gfm";

function Services() {
    const [markdownContent, setMarkdownContent] = useState(null);
    const { service } = useParams();

    useEffect(() => {
        const sampleMarkdown = require(`../constants/markdown/${service}.md`);
        fetch(sampleMarkdown)
            .then((response) => response.text())
            .then((text) => setMarkdownContent(text || "")).catch(error => {
                console.log("error =>", error);
            });
    }, [service]);

    return (
        <div className="Services">
            <Box sx={{
                "h1": {
                    textAlign: "center",
                },
                "img": {
                    maxWidth: "100%",
                    margin: "auto",
                },
                'tr': {
                    borderTop: '1px solid #c6cbd1',
                    background: '#fff',
                  },
                  'th, td': {
                    padding: '6px 13px',
                    border: '1px solid #dfe2e5',
                  },
                  'table tr:nth-child(2n)': {
                    background: '#f6f8fa',
                  },
            }}>
                <Box sx={{ paddingY: "50px" }}>
                    <Row>
                        <Col md={5} lg={7} className="mx-auto" style={{ display: "flex", flexDirection: "column" }}>
                           {markdownContent && <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>}
                        </Col>
                    </Row>
                </Box>
            </Box>
        </div>
    );
}

export default Services;
