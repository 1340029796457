import { Box } from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import React, { useContext, useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { MdCheckCircle, MdError } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import ReactWOW from 'react-wow';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap';
import { EmptyResult, Loader, OTPInputModal, TransactionSuccess } from '../../../components/misc';
import AccountContext from "../../../contexts/AccountContext";
import ActionContext from "../../../contexts/ActionContext";
import {
  createEntry,
  getEntry,
  NetworkErrorHandler
} from '../../../utils/dataGenerator';

const _ = require('lodash');

const BeneficiaryTransfer = props => {
  const initPayload = {
    bank: '',
    accountNumber: '',
    accountName: '',
    amount: null,
    addBeneficiary: false,
    transaction_source: 'BENEFICIARY',
    currency: '',
  };
  const actionContext = useContext(ActionContext);
  const accountContext = useContext(AccountContext);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState(null);
  const [showOTP, toggleOTP] = useState(false);
  const [payload, setPayload] = useState(initPayload);
  const [state, setState] = useState({
    transferring: false,
    isFetching: true,
    isSuccess: false,
  });

  useEffect(() => {
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setPayload({ ...payload, currency: user.displayCurrency || 'USD' });
      getEntry(`transactions/beneficiaries`, (res, err) => {
        if (!err) {
          const response = res.data;
          const { beneficiaries } = response;
          setBeneficiaries(beneficiaries);
          setState({ ...state, isFetching: false })
        } else {
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{err.message}
            </div>,
            { type: 'error' },
          );
          setError(err.message);
          setTimeout(() => {
            setError(null);
          }, 1500);
        }
      });
    });
  }, []);

  const selectBen = ben => {
    const { accountName, accountNumber, bank, beneficiary, isInternal } = ben;
    setPayload((old) => ({
      ...old,
      accountName,
      accountNumber,
      bank,
      beneficiary,
      isInternal,
    }));
    setSelected(ben);
  };

  const transfer = e => {
    try {
      e.preventDefault();
      if (state.transferring) return;
      if (payload.amount <= 0)
        throw Error('How much do you intend to transfer?');
      if (!payload.description) throw Error('Please enter a description');
      toggleOTP(true);
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{error.message}
        </div>,
        { type: 'error' },
      );
      setError(error.message);
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  const startTransfer = () => {
    try {
      setState({ ...state, transferring: true });
      createEntry(
        `transactions/transfer`,
        payload,
        (res, err) => {
          if (!err) {
            const response = res.data;
            actionContext.showNotification(
              <div className="align-center">
                <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
                &nbsp;Transfer Successful
              </div>,
              { type: 'success' },
            );
            setState({ ...state, isLoading: false, isSuccess: true });
          } else {
            actionContext.showNotification(
              <div className="align-center">
                <MdError style={{ fontSize: 20, color: 'red' }} />
                &nbsp;{NetworkErrorHandler(err)}
              </div>,
              { type: 'error' },
            );
            setError(NetworkErrorHandler(err));
            setTimeout(() => {
              setError(null);
            }, 1500);
            setState({ ...state, isLoading: false });
          }
        },
      );
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{error.message}
        </div>,
        { type: 'error' },
      );
      setError(error.message);
      setTimeout(() => {
        setError(null);
      }, 1500);
      setState({ ...state, isLoading: false });
    }
  }

  return (
    // <ReactWOW animation="fadeInRight" duration="0.5s">
    <>
      {showOTP ? (
        <OTPInputModal
          open={showOTP}
          toggle={() => toggleOTP(false)}
          successCallback={startTransfer}
        />
      ) : (
        <div />
      )}
      <div className="bg-white shadow p-3 p-md-5">
        <h5 className="orange-text mb-0 stretched-text-sm">
          Transfer to Beneficiary
        </h5>
        {_.isEmpty(beneficiaries) ? (
          <EmptyResult
            text={state.isFetching ? <Loader /> : 'You have no beneficiaries'}
          />
        ) : state.isSuccess ? (
          <TransactionSuccess
            data={payload}
            dismiss={() => {
              setSelected(null);
              setPayload(initPayload);
              setState({ ...state, isSuccess: false });
            }}
          />
        ) : (
          <>
            <Row className="mt-4">
              <Col md={6}>
                {_.map(beneficiaries, function (ben, index) {
                  return (
                    <Row
                      key={index}
                      onClick={() => selectBen(ben)}
                      className={`border-bottom bg-white clickable --with-highlight beneficiary-item p-0 ${selected && !_.isEqual(selected, ben)
                          ? 'hidden'
                          : _.isEqual(selected, ben)
                            ? 'selected'
                            : ''
                        }`}
                    >
                      <Col xs={10} className="p-2">
                        <strong className="bold stretched-text-xs">
                          {ben.accountName}
                        </strong>
                        <br />
                        <span
                          style={{ fontSize: 13 }}
                          className="stretched-text-xs orange-text"
                        >
                          {ben.bank}
                        </span>
                        <br />
                        <span
                          style={{ fontSize: 13 }}
                          className="stretched-text-xs grey-text"
                        >
                          {ben.accountNumber}
                        </span>
                      </Col>
                      <Col xs={2} className="p-2 aligner-container">
                        <BsArrowRight
                          style={{
                            fontSize: 20,
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
                {selected && (
                  <div className="align-right p-0">
                    <Button
                      disabled={state.transferring}
                      onClick={() => !state.transferring && setSelected(null)}
                      color="dark"
                      size="sm"
                      className="dark-btn mx-0"
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </Col>
              <Col md={6}>
                {!selected ? (
                  <EmptyResult text={'Select a Beneficiary'} />
                ) : (
                  // <ReactWOW animation="fadeIn" duration="0.5s" delay="0.5s">
                  <>
                    <Form onSubmit={transfer}>
                      <FormGroup>
                        <Label>Amount</Label>
                        <InputGroup>
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#f3f3f3", width: "30px" }}>
                            {getSymbolFromCurrency(payload.currency)}
                          </Box>
                          <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale={true}
                            placeholder="0.00"
                            value={payload.amount}
                            displayType={'input'}
                            className="form-control font-weight-medium align-right stretched-text-xs"
                            onChange={v =>
                              setPayload({
                                ...payload,
                                amount: parseFloat(v.target.value),
                              })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Label>Description</Label>
                        <Input
                          type="textarea"
                          defaultValue={payload.description}
                          onChange={v =>
                            setPayload({
                              ...payload,
                              description: v.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="align-right">
                        {state.transferring ? (
                          <Loader />
                        ) : (
                          <Button
                            disabled={state.transferring}
                            type="submit"
                            color="dark"
                            className={`mx-0 ${error ? 'animated shake' : ''}`}
                          >
                            Proceed&nbsp;
                            <BsArrowRight
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </Button>
                        )}
                      </FormGroup>
                    </Form>
                    {/* </ReactWOW> */}
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
      {/* </ReactWOW> */}
    </>
  );
};

export default BeneficiaryTransfer;
