import React from 'react';
import { Col, Row } from 'reactstrap';
import UserAccountNav from '../../../components/Header/UserAccountNav';
import { EmptyResult, ModalLoader } from '../../../components/misc';
import { MdLayers } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import BeneficiaryTransfer from './BeneficiaryTransfer';
import InternalTransfer from './InternalTransfer';
import OthersTransfer from './OthersTransfer';
import AccountContext from "../../../contexts/AccountContext";

const mql = window.matchMedia(`(min-width: 800px)`);
const _ = require('lodash');

const Transfers = props => {
  const accountContext = React.useContext(AccountContext);
  const [user, setUser] = React.useState(null);
  const [transferType, setTranstype] = React.useState('beneficiaries');
  const [sidebarDocked, setSidebarDocked] = React.useState(true);
  const [state, setState] = React.useState({
    isFetching: false,
  });
  const mediaQueryChanged = () => {
    setSidebarDocked(mql.matches);
  };

  React.useEffect(() => {
    mql.addListener(mediaQueryChanged);
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setUser(user);
    });
  }, []);

  return (
    <UserAccountNav current="transfers" sidebarDocked={sidebarDocked}>
      {state.isFetching ? (
        <ModalLoader open={state.isFetching} />
      ) : (
        <div className="mt-0">
          <div
            style={{
              minHeight: '100vh',
              paddingTop: sidebarDocked ? 50 : 100,
            }}
          >
            <div className="p-3 p-md-4">
              <h4 className="orange-text title-text">Transfers</h4>
            </div>
            <Row>
              <Col md={6}>
                <div className="bg-white shadow p-3 p-md-5">
                  <h5 className="grey-text mb-0 stretched-text-sm">
                    Transfer Funds
                  </h5>
                  <small className="stretched-text-xs">
                    Kindly choose an option from the list
                  </small>
                  <ul className="list-group mt-3 transfer-options">
                    <li
                      onClick={() => setTranstype('beneficiaries')}
                      className={`list-group-item clickable ${
                        transferType == 'beneficiaries' ? 'active' : ''
                      }`}
                    >
                      <MdLayers style={{ fontSize: 20 }} />
                      <span className="ml-3 stretched-text-xs">
                        Transfer to Beneficiaries
                      </span>
                      <BsArrowRight
                        style={{
                          fontSize: 20,
                          right: 10,
                          position: 'absolute',
                        }}
                      />
                    </li>
                    <li
                      onClick={() => setTranstype('internal')}
                      className={`list-group-item clickable ${
                        transferType == 'internal' ? 'active' : ''
                      }`}
                    >
                      <MdLayers style={{ fontSize: 20 }} />
                      <span className="ml-3 stretched-text-xs">
                        Internal Transfer
                      </span>
                      <BsArrowRight
                        style={{
                          fontSize: 20,
                          right: 10,
                          position: 'absolute',
                        }}
                      />
                    </li>
                    <li
                      onClick={() => setTranstype('others')}
                      className={`list-group-item clickable ${
                        transferType == 'others' ? 'active' : ''
                      }`}
                    >
                      <MdLayers style={{ fontSize: 20 }} />
                      <span className="ml-3 stretched-text-xs">
                        Transfer to Others
                      </span>
                      <BsArrowRight
                        style={{
                          fontSize: 20,
                          right: 10,
                          position: 'absolute',
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6}>
                {transferType == 'beneficiaries' ? (
                  <BeneficiaryTransfer />
                ) : transferType == 'internal' ? (
                  <InternalTransfer />
                ) : transferType == 'others' ? (
                  <OthersTransfer />
                ) : (
                  <EmptyResult text={'Invalid transfer option provided'} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </UserAccountNav>
  );
};

export default Transfers;
