import React, { useContext, useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FcLock } from 'react-icons/fc';
import { ModalLoader } from '../../../components/misc';
import AccountContext from "../../../contexts/AccountContext";
import AccountSecurity from './AccountSecurity';

const _ = require('lodash');

const Preferences = props => {
  const accountContext = useContext(AccountContext);
  const [user, setUser] = useState(null);
  const [hover, setHovered] = useState(null);
  const [selectOpt, setSelectedOpt] = useState(null);
  const [state, setState] = useState({
    isFetching: true,
  });

  useEffect(() => {
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setUser(user);
      setState({ ...state, isFetching: false });
    });
  }, []);

  return (
    <>
      {state.isFetching ? (
        <ModalLoader open={state.isFetching} />
      ) : (
        <>
          <div
            style={{
              minHeight: '100vh',
            }}
          >
            <div className="p-3 p-md-4">
              <h4 className="orange-text title-text">Preferences</h4>
            </div>
            <div className="align-center">
              <div className="thumbnail-lg mx-auto aligner-container shadow-lg">
                <span className="white-text">
                  {user.first_name.substr(0, 1)}
                </span>
              </div>
              <p style={{ fontSize: 20 }} className="mb-0">{`${
                user.first_name
              } ${user.last_name}`}</p>
              <p className="my-0 grey-text capitalize" style={{ fontSize: 13 }}>
                {user.username}
              </p>
            </div>
            <ul className="list-group">
              {/* account settings */}
              <li
                className={`list-group-item my-1 clickable --with-highlight py-3 border-0 ${
                  hover == 'accSet' && !selectOpt ? 'shadow-lg' : ''
                } ${
                  selectOpt && !_.isEqual(selectOpt, 'accSet')
                    ? 'hidden'
                    : _.isEqual(selectOpt, 'accSet')
                    ? 'selected'
                    : ''
                }`}
                onMouseEnter={() => setHovered('accSet')}
                onMouseLeave={() => setHovered(null)}
                onClick={() => setSelectedOpt('accSet')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="circle-sm bg-grey aligner-container mr-3">
                  <FcLock style={{ fontSize: 23 }} />
                </div>
                <span
                  className="dark-text stretched-text-xs"
                  style={{ fontSize: 16 }}
                >
                  Account Security
                </span>
                {!selectOpt && (
                  <BsArrowRight className="ml-auto" style={{ fontSize: 20 }} />
                )}
              </li>
              {/* currency display */}
              {/* <li
                className={`list-group-item my-1 clickable --with-highlight py-3 border-0 ${
                  hover == 'curSet' && !selectOpt ? 'shadow-lg' : ''
                } ${
                  selectOpt && !_.isEqual(selectOpt, 'curSet')
                    ? 'hidden'
                    : _.isEqual(selectOpt, 'curSet')
                    ? 'selected'
                    : ''
                }`}
                onMouseEnter={() => setHovered('curSet')}
                onMouseLeave={() => setHovered(null)}
                onClick={() => setSelectedOpt('curSet')}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="circle-sm bg-grey aligner-container mr-3">
                  <FcCurrencyExchange style={{ fontSize: 23 }} />
                </div>
                <span
                  className="dark-text stretched-text-xs"
                  style={{ fontSize: 16 }}
                >
                  Default Display Currency
                </span>
                {!selectOpt && <BsArrowRight className="ml-auto" style={{ fontSize: 20 }} />}
              </li> */}
            </ul>
            {selectOpt == 'accSet' && (
              <div className="animated fadeIn">
                <AccountSecurity
                  dismiss={() => setSelectedOpt(null)}
                  onSuccess={() => setSelectedOpt(null)}
                />
              </div>
            )}
            {/* {selectOpt == 'curSet' && <DefaultCurrency dismiss={() => setSelectedOpt(null)} />} */}
          </div>
        </>
      )}
    </>
  );
};

export default Preferences;
