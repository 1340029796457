import React from 'react';
import { MdError } from 'react-icons/md';
import { Row } from 'reactstrap';
import UserAccountNav from '../../../components/Header/UserAccountNav';
import AccountContext from "../../../contexts/AccountContext";
import ActionContext from "../../../contexts/ActionContext";
import { getEntry, NetworkErrorHandler } from '../../../utils/dataGenerator';
import TransactionsTable from './TransactionsTable';

const mql = window.matchMedia(`(min-width: 800px)`);
const _ = require('lodash');

const Transactions = props => {
  const accountContext = React.useContext(AccountContext);
  const actionContext = React.useContext(ActionContext);
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [transactions, setTransactions] = React.useState([]);
  const [sidebarDocked, setSidebarDocked] = React.useState(true);
  const [meta, setMeta] = React.useState({
    page: 1,
    pageLimit: 10,
  });
  const [state, setState] = React.useState({
    isFetching: false,
  });
  const mediaQueryChanged = () => {
    setSidebarDocked(mql.matches);
  };

  React.useEffect(() => {
    mql.addListener(mediaQueryChanged);
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setUser(user);
      getTransactions(meta.page, meta.pageLimit);
    });
  }, []);


  const getTransactions = (page, pageLimit) => {
    setState({ ...state, isFetching: true });
    getEntry(
      `transactions/history?&$page=${page}&$limit=${Math.pow(10, 5)}`,
      (res, error) => {
        if (!error) {
          const response = res.data;
          console.log('trx', response);
          setMeta({ ...meta, ...response });
          setTransactions(response.transactions);
          setTimeout(() => setState({ ...state, isFetching: false }), 1000);
        } else {
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{NetworkErrorHandler(err)}
            </div>,
            { type: 'error' },
          );
          setError(NetworkErrorHandler(err));
          setTimeout(() => {
            setError(null);
          }, 1500);
          setState({ ...state, isLoading: false });
        }
      },
    );
  };

  return (
    <UserAccountNav current="transactions" sidebarDocked={sidebarDocked}>
      {
        <div className="mt-0">
          <div
            style={{
              minHeight: '100vh',
              paddingTop: sidebarDocked ? 50 : 100,
            }}
          >
            <div className="p-3 p-md-4 mx-md-4">
              <h4 className="orange-text title-text">Transactions</h4>
              <TransactionsTable transactions={transactions} isLoading={state.isFetching} />
            </div>
            <Row />
          </div>
        </div>
      }
    </UserAccountNav>
  );
};
export default Transactions;
