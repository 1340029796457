import { Avatar, Box, Paper, Typography } from "@mui/material";
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Col, Row } from "reactstrap";
import { GlassMask } from "./styledComponents/MainStyles";

const items = [
    {
        id: 1,
        title: 'Individuals',
        description: 'We offer a comprehensive wealth and financial planning service that helps them achieve their financial goals and secure their future.',
        image: 'https://img.freepik.com/free-photo/people-enjoying-berry-snack-outdoors_52683-107515.jpg',
    },
    {
        id: 2,
        title: 'Family Officies',
    description: 'We provide support and guidance in establishing and developing a family office, or reviewing and enhancing their current structure.',
        image: 'https://img.freepik.com/free-photo/four-young-latin-people-sitting-office-lounge-using-laptops-mobile-phones-relaxing-during-work-break_662251-1576.jpg',
    },
    {
        id: 3,
        title: 'Charities',
        description: 'We also work with charities and not-for-profit organizations to help them protect and grow their assets, and achieve their mission.',
        image: 'https://img.freepik.com/free-photo/group-people-volunteering-foodbank-poor-people_23-2149012197.jpg',
    },
    {
        id: 4,
        title: 'Intermediaries and corporates',
        description: 'Our secure custody solutions ensure that assets are safe and protected, while our execution services help clients achieve their investment objectives with precision and efficiency.',
        image: 'https://img.freepik.com/free-photo/accountant-office_1098-13392.jpg',
    },
];

const ClientsHomeGrid = () => {
    return (
        <Carousel autoPlay infiniteLoop centerMode interval={5000} transitionTime={1000} showThumbs={false} showArrows={false} showIndicators={false} showStatus={false}>
            {items.map((item) => (
                <div key={item.id}>
                    <GlassMask sx={{ borderRadius: "10px", boxShadow: "none" }}>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Avatar
                                alt="IMAGE"
                                src={item.image}
                                sx={{ width: 150, height: 150, padding: "10px" }}
                            />
                            <Paper sx={{ p: 1, width: "100%" }}>
                                <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>{item.title}</Typography>
                                <Typography sx={{ textAlign: "left" }}>{item.description}</Typography>
                            </Paper>
                        </Box>
                    </GlassMask>
                </div>
            ))}
        </Carousel>
    );
};

export default ClientsHomeGrid;
