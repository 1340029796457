import React from 'react';
import { Box, Paper, Typography } from "@mui/material";
import { Col, Row } from "reactstrap";
import WomanImage from "../assets/images/woman-smiling-drawn-graphics.jpg"
import TuneTwoToneIcon from '@mui/icons-material/TuneTwoTone';
import StyleTwoToneIcon from '@mui/icons-material/StyleTwoTone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';

const PrivateBanking = () => {
    return (
        <Box sx={{ p: 1, my: 10 }} className="px-2 px-md-5">
        <Row style={{ flex: 1, display: "flex", alignItems: "center" }}>
            <Col className='mx-auto' xl={4} md={5} >
                <Box className="shadow-lg" sx={{ backgroundImage: `url(${WomanImage})`, height: "500px", width: "100%", backgroundSize: "cover", borderRadius: "10px" }} />
            </Col>
            <Col className='mx-auto' xl={5} md={7} >
                <Typography sx={{ fontSize: "4rem", fontWeight: "bold", color: "theme.default" }}>Private Banking</Typography>
                <Typography>Our top priority is the protection and creation of wealth through proper financial planning and asset diversification. We offer a range of financial services that cater to the diverse needs of our clients, including investment services, lending for investment purposes, deposits, funds management, property procurement (both domestically and overseas), inheritance tax planning, and special investment products.</Typography>
                <Row className='my-3'>
                    <Col md={6} lg={4} style={{ marginBottom: "5px" }}>
                        <Paper sx={{ p: 1, height: "100%" }}>
                            <Box className="shadow" sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#ed1b24", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "auto", mb: 2 }}><TuneTwoToneIcon sx={{ fontSize: "2rem", color: "#FFF" }} /></Box>
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Personalized Investment Management</Typography>
                            <Typography sx={{ fontSize: "13px" }}>Eccount Money Private Banking provides a highly personalized investment management service that caters to the unique needs and goals of each high net worth client.</Typography>
                        </Paper>
                    </Col>
                    <Col md={6} lg={4} style={{ marginBottom: "5px" }}>
                        <Paper sx={{ p: 1, height: "100%" }}>
                            <Box className="shadow" sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#2688c9", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "auto", mb: 2 }}><StyleTwoToneIcon sx={{ fontSize: "2rem", color: "#FFF" }} /></Box>
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Wide Range of Financial Services</Typography>
                            <Typography sx={{ fontSize: "13px" }}>We provide diverse financial services such as investment, lending, deposits, funds management, property acquisition (domestic & overseas), inheritance tax planning, and specialized investment products.</Typography>
                        </Paper>
                    </Col>
                    <Col md={12} lg={4} style={{ marginBottom: "5px" }}>
                        <Paper sx={{ p: 1, height: "100%" }}>
                            <Box className="shadow" sx={{ width: "50px", height: "50px", borderRadius: "50%", backgroundColor: "#8ec63f", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "auto", mb: 2 }}><VerifiedTwoToneIcon sx={{ fontSize: "2rem", color: "#FFF" }} /></Box>
                            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Emphasis on Quality Investments</Typography>
                            <Typography sx={{ fontSize: "13px" }}>We believe in quality investments for the medium to long term and spreading risk across diverse asset classes. Our focus is on investments with high potential returns and low risk to safeguard and increase our clients' wealth.</Typography>
                        </Paper>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Box>
    );
}
export default PrivateBanking;