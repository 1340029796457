import Axios from 'axios';
import Enums from "../constants/enums";
// import { base_url, STORAGE_KEYS } from './constants/enums';

const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    // let location = { latitude: null, longitude: null };
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;
    //       location["latitude"] = latitude
    //       location["longitude"] = longitude
    //       resolve(location)
    //     },
    //     (error) => {
    //       console.error(`Error getting user location: ${error.message}`);
    //       resolve({ latitude: 0, longitude: 0 })
    //     }
    //   );
    // } else {
    //   console.error("Geolocation is not supported by this browser.");
      resolve({ latitude: 0, longitude: 0 })
    // }
  })
}


export const getUserDeviceInfo = async () => {
  const { userAgent, platform, } = window.navigator;
  const { latitude, longitude } = await getUserLocation()
  return {
    userAgent,
    platform,
    latitude,
    longitude
  }
}

const getHeaders = async (token) => {
  const headers = { Authorization: `Bearer ${token || localStorage.getItem(Enums.STORAGE_KEYS.TOKEN)}` }
  const { latitude, longitude, platform, userAgent } = await getUserDeviceInfo()
  headers["X-User-Device"] = userAgent
  headers["X-User-Platform"] = platform
  headers["X-User-Latitude"] = latitude
  headers["X-User-Longitude"] = longitude
  return { headers }
}

export const deleteEntry = (path, callback) => {
  getHeaders().then(headers => {
    Axios.delete(`${Enums.BASE_URL}/${path}`, headers)
      .then(res => {
        if (callback) return callback(res, null);
      })
      .catch(error => {
        console.error(error);
        if (callback) return callback(null, error);
      });
  })
};

export const getEntry = (path, callback, token = null) => {
  getHeaders(token).then(headers => {
    Axios.get(`${Enums.BASE_URL}/${path}`, headers)
      .then(res => {
        if (callback) return callback(res, null);
      })
      .catch(error => {
        console.error(error);
        if (callback) return callback(null, error);
      });
  })
};

export const createEntry = (path, payload, callback, token = null) => {
  getHeaders(token).then(headers => {
    Axios.post(`${Enums.BASE_URL}/${path}`, payload, headers)
      .then(res => {
        if (callback) return callback(res, null);
      })
      .catch(error => {
        console.error(error);
        if (callback) return callback(null, error);
      });
  })
};

export const updateEntry = (path, payload, callback, token = null) => {
  getHeaders(token).then(headers => {
    Axios.post(`${Enums.BASE_URL}/${path}`, payload, headers)
      .then(res => {
        if (callback) return callback(res, null);
      })
      .catch(error => {
        console.error(error);
        if (callback) return callback(null, error);
      });
  })
};

export const NetworkErrorHandler = error => {
  return error.response
    ? error.response.data.message
    : error.request
      ? error.request.statusText
      : error.message;
};

export const OTP_GEN = (len) => {
  var digits = "0123456789";
  let OTP = "";
  for (let i = 0; i < len; i++) {
    OTP += digits[Math.floor(Math.random() * 10)];
  }
  return OTP;
};