import { createGlobalStyle } from 'styled-components';

const primaryColor = '#2688c9';
const deepGray = '#58595b';
const lightGray = '#f5f5f5';
const dark = '#333';
const green = '#8ec63f';
const white = '#FFF';
const dangerColor = '#ed1b24'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    overflow-x: hidden !important;
    color: #333;
    *{
      font-family: 'Open Sans', arial, sans-serif !important
    }
    button{
      width: fit-content !important;
    }
  }
  ::selection {
    color: #FFF;
    background: ${dark}; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    color: #FFF;
    background: ${dark}; /* Gecko Browsers */
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  .bg-blur{
    filter: blur(8px);
  }

  .side-modal{
    height: 100vh !important;
    overflow: auto;
    top: 0px;
    position: fixed;
    right: 0px;
    z-index: 1500 !important;
  }

  .thumbnail-lg{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${primaryColor};
    span{
      font-size: 30px
    }
  }


  .thumbnail-xs{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${primaryColor};
    span{
      font-size: 15px
    }
  }

  nav{
    border-bottom: 5px solid ${primaryColor} !important;
  }

  .nav-item{
    *{
      font-size: 13px !important;
      font-family: "Open Sans", arial, sans-serif !important;
      color: #777 !important;
      word-spacing: 8px;
      letter-spacing: 4px;
      font-weight: bold;
      text-transform: uppercase
    }
  }

  .nav-item:hover{
    *{
      color ${primaryColor} !important
    }
  }

  .nav-item.filled{
    background-color: ${primaryColor} !important;
    *{
      color: #FFFFFF !important;
    }
  }

  .hero{
    min-height: 80vh;
    background-attachment: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: grid;
    align-content: center;
  }

  .hero.about{
  }

  .hero.our-story{
    min-height: 80vh;
  }

  .hero.our-promise{
    min-height: 50vh;
  }

  .hero.contact{
    min-height: 40vh;
  }

  .hero.contact-orange{
    min-height: 60vh;
  }

  .align-vertical-center{
    display: grid;
    align-content: center;
  }

  .hero-text{
    background-color: #FFF;
    padding: 10px;
    font-size: 2.2rem;
    color: #777
  }

  .hero-footer{
    *{
      font-size: 13px;
      font-weight: light !important;
      color: ${deepGray};
    }
  }

  .sub-hero-text{
    font-size: 20px;
    color: #333 !important;
    font-weight: lighter;
    word-spacing: 10px;
  }
  
  .edit-btn-container{
    top: 0px;
    width: 100vw;
    align-items: right;
    position: inherit;
    z-index:100;
    justify-content: right;
    display: grid;
  }

  .white-text{
    color: #FFF !important;
  }

  .orange-text{
    color: ${primaryColor} !important
  }

  .danger-text {
    color: ${dangerColor} !important;
  }

  .success-text {
    color: ${primaryColor} !important;
  }

  .bg-orange{
    background-color ${primaryColor} !important
  }

  .bg-grey{
    background-color ${lightGray}
  }

  .circle-sm{
    width: 50px;
    height: 50px;
    border-radius: 50px
  }
  .aligner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stretched-text-md{
    letter-spacing: 5px;
    word-spacing: 10px
  }

  .stretched-text-sm{
    letter-spacing: 3px;
    word-spacing: 7px
  }

  .stretched-text-xs{
    letter-spacing: 2px;
    word-spacing: 5px
  }

  .medium-hero{
    min-height: 60vh;
    background-color: #d7d7d7;
    background-attachment: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: grid;
    align-content: center;

    h4{
      word-spacing: 10px !important;
      letter-spacing: 5px;
    }

    img{
      width: 100%;
      margin-top: -20px;
    }
  }

  .after-medium-hero{
    border-bottom: 5px solid #d7d7d7;
    h2{
      font-size: 41px;
      font-weight: 300 !important;
      line-height: 1.0732em;
    }
    span{
      font-size: 16px;
      color: #777;
      letter-spacing: 1px;

    }
  }
  .align-center{
    text-align: center !important
  }

  .align-right{
    text-align: right !important
  }

  ul{
    li{
      font-size: 14px !important;
      margin-bottom: 2px
    }
  }

  ul.transfer-options{
    li{
      border-width: 0px !important;
      margin-bottom: 1px;
    }
    li:hover {
      background-color: ${lightGray} !important;
      color: ${primaryColor} !important
    }
    
    li.active{
      background-color: ${green} !important;
      color: ${white} !important
    }
  }

  .hidden{
    display: none !important
  }

  .beneficiary-item.selected{
    background-color: #333 !important;
    border-radius: 5px;
    *{color: #FFF !important}
  }

  form{
    label{
      font-size: 13px !important;
      color: ${primaryColor} !important;
      letter-spacing: 3px;
      word-spacing: 5px
    }
  }
  .services-container{
    h4{
      color: #333;
      font-size: 25px;
      word-spacing: 15px;
      letter-spacing: 10px;
      text-transform: uppercase;
    }

    .icon-container{
      width: 100px;
      height: 100px;
      border-radius: 100px;

      *{
        font-size: 60px;
        color: #777;
      }
    }

    .icon-container.active{
      background-color: ${primaryColor} !important;
      *{
        color: #FFF
      }
    }

    .capitalize{
      text-transform: capitalize !important;
    }

    .title{
      font-size: 14px;
      text-transform: capitalize;
      word-spacing: 10px;
      letter-spacing: 5px
    }

    .title.active{
      color: ${primaryColor}
    }
  }

  .clickable:hover{
    cursor: pointer !important;
  }

  .clickable.--with-highlight:hover{
    opacity: 0.9 !important;
  }

  .bolder{
    font-weight: 900 !important
  }

  .footer{
    .bg-dark{
      background-color: ${deepGray} !important;
    }

    .list-group{
      padding: 0px;
      background: transparent !important;
    }


    li.clickable, span, a{
      color: #FFFFFFCC !important;
      border-width: 0px !important
      font-size: 13px;
      font-family: sans-serif !important
    }

    li.clickable:hover, a{
      color: ${primaryColor} !important
    }

    h6{
      font-size: 13px;
      word-spacing: 10px;
      letter-spacing: 5px
    }

    a:hover{
      text-decoration: underline !important
    }

    .social-button{
      font-size: 25px;
      color: #FFF;
    }

    .dates{
      word-spacing: 8px;
      letter-spacing: 5px;
    }
  }

    .submit-btn{
      background-color: ${primaryColor} !important;
      color: #FFF !important;
      width: 200px !important;
      border-radius: 50px;
      font-size: 18px
    }

    .form-text{
      font-size: 18px;
      color:#777
    }

    .sub-nav{
      position: fixed;

      width: 100%;
      margin-top: 85px !important;
      .sub-nav-item:hover{
        background-color: #333
      }
    }
    .sidebar-container{
      .list-group-item{
        border: 0px !important;
        letter-spacing: 5px;
        word-spacing: 10px
      }
      .list-group-item:hover,
      .list-group-item.active{
        background-color: ${lightGray} !important;
        color: #333
      }
      .pro{
        bottom: 0px;
        position: absolute;
        width: -webkit-fill-available;
      }
    }

    .transparent{
      background-color: transparent !important
    }

    .loader-modal{
      .modal-content{
        background: none !important;
        box-shadow: none !important;
        border: 0px;
        *{
          background: transparent !important
        }
      }
    }

    .title-text{
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 7px;
      word-spacing: 15px;
    }

    .visible-sm{
      display: none !important
    }

    .admin-card{
      height: 100%
    }

    @media screen and (max-width: 425px) {
      .hidden-sm{
        display: none !important
      }
      .visible-sm{
        display: inline-block !important
      }
    }
`;

export default GlobalStyle;
