import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSound from 'use-sound';
import AlertContextProvider from './contexts/AlertContextProvider';
import { AuthProvider } from './contexts/AuthContext';
import AccountRouter from './routes/AccountRouter';
import AdminRouter from './routes/AdminRouter';
import AppRouter from './routes/AppRouter';

const errorFx = require("./assets/sounds/320181_dland_hint.mp3")
const successFx = require('./assets/sounds/success_notification_alert_A_major.mp3');


const App = () => {
	const history = createBrowserHistory({})
	const [showPrefSide, setShowPrefSide] = React.useState(false);
	const [showAccInfoSide, setShowAccInfoSide] = React.useState<any>(null);
	const [showNewAccSide, setShowNewAccSide] = React.useState<boolean>(false);
	const [playErrorFx, errorFxOptions] = useSound(errorFx);
	const [playSuccessFx, successFxOptions] = useSound(successFx);

	const notify = (message: string, options: any) => {
		// if (errorFxOptions.isPlaying) errorFxOptions.stop();
		// if (successFxOptions.isPlaying) successFxOptions.stop();
		switch (options.type) {
			case 'success':
				playSuccessFx();
				break;

			default:
				playErrorFx();
				break;
		}
		toast.dark(message, {
			position: 'top-center',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const theme = createTheme({
		palette: {
			mode: 'light',
			background: {
				default: '#FFF',
				paper: '#f7f7f7'
			},
			text: {
				primary: '#1c1c1c',
				secondary: '#b0bec5'
			}
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AlertContextProvider>
				<AuthProvider>
					<BrowserRouter>
						<div style={{ overflowX: "hidden" }}>
							<AccountRouter />
							<AdminRouter />
							<AppRouter />
						</div>
					</BrowserRouter>
				</AuthProvider>
			</AlertContextProvider>
		</ThemeProvider>
	);
}

export default App
