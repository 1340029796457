import { Box } from "@mui/material";
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { MdCheckCircle, MdError } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Switch from 'react-switch';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap';
import { Loader } from '../../components/misc';
import Enums from "../../constants/enums";
import ActionContext from "../../contexts/ActionContext";
import {
  createEntry,
  NetworkErrorHandler,
  OTP_GEN
} from '../../utils/dataGenerator';

const _ = require('lodash');

const NewTransactionForm = props => {
  const { user } = props;
  const initPayload = {
    user: user._id,
    bank: '',
    accountNumber: '',
    accountName: '',
    amount: null,
    addBeneficiary: false,
    transaction_source: 'ACCOUNT_MANAGER',
    currency: user.displayCurrency || 'USD',
    isCredit: false,
    createdAt: moment().utc(),
  };
  const actionContext = useContext(ActionContext);
  const [error, setError] = useState();

  const [payload, setPayload] = useState(initPayload);
  const [banks, setBanks] = useState([]);
  const [state, setState] = useState({
    isLoading: false,
  });

  React.useEffect(() => {
    // console.log('date', moment().toDate());
    setBanks(
      _.map(_.uniq(Enums.BANKS[user?.displayCurrency || 'USD']), function (bank, index) {
        return { label: bank, id: index };
      }),
    );
  }, [user]);

  const autoGen = () => {
    try {
      if (payload.amount <= 0) throw Error('Kindly input a valid amount');
      if (!payload.bank) throw Error('Please select a Bank');
      if (!payload.accountNumber)
        throw Error('Please enter a valid account number');
      if (!payload.accountName)
        throw Error('Please enter a valid account holder name');
      // generate description
      const transaction_id = OTP_GEN(10);
      const description = `-- ${payload.isCredit ? 'CREDIT' : 'DEBIT'
        } --\nACCT: ${payload.accountNumber}\nAMT: ${getSymbolFromCurrency(
          payload.currency,
        )}${payload.amount}\nDESC: TrxID - ${transaction_id} ${payload.bank
        }\nDate: ${moment(payload.createdAt).format('MM/DD/YYYY hh:mm a')}`;
      setPayload({ ...payload, description });
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{NetworkErrorHandler(error)}
        </div>,
        { type: 'error' },
      );
      setError(NetworkErrorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  const validate = e => {
    try {
      e.preventDefault();
      if (payload.amount <= 0) throw Error('Kindly input a valid amount');
      if (!payload.bank) throw Error('Please select a Bank');
      if (!payload.accountNumber)
        throw Error('Please enter a valid account number');
      if (!payload.accountName)
        throw Error('Please enter a valid account holder name');
      if (!payload.description) throw Error('Please enter a description');
      startTransfer();
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{NetworkErrorHandler(error)}
        </div>,
        { type: 'error' },
      );
    }
  };

  const startTransfer = () => {
    setState({ ...state, isLoading: true });
    createEntry(`transactions/transfer/${user._id}`, payload, (res, err) => {
      if (!err) {
        setPayload(initPayload);
        if (props.onSuccess) props.onSuccess();
        actionContext.showNotification(
          <div className="align-center">
            <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
            &nbsp;Transfer Successful
          </div>,
          { type: 'success' },
        );
        setState({ ...state, isLoading: false, isSuccess: true });
      } else {
        actionContext.showNotification(
          <div className="align-center">
            <MdError style={{ fontSize: 20, color: 'red' }} />
            &nbsp;{NetworkErrorHandler(err)}
          </div>,
          { type: 'error' },
        );
        setState({ ...state, isLoading: false });
      }
    });
  };

  return (
    <>
      <Form onSubmit={validate}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Amount</Label>
              <InputGroup>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#f3f3f3", width: "30px" }}>
                  {getSymbolFromCurrency(payload.currency)}
                </Box>
                <NumberFormat
                  isDisabled={state.isLoading}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  placeholder="0.00"
                  value={payload.amount}
                  displayType={'input'}
                  className="form-control font-weight-medium align-right stretched-text-xs"
                  onChange={v =>
                    setPayload({
                      ...payload,
                      amount: parseFloat(v.target.value),
                    })
                  }
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Bank</Label>
              <Select
                isDisabled={state.isLoading}
                options={banks}
                className="capitalize"
                onChange={val => setPayload({ ...payload, bank: val.label })}
                isSearchable={true}
                placeholder={'Select Bank'}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Account No. / IBAN</Label>
              <Input
                value={payload.accountNumber}
                disabled={state.isLoading}
                type={'text'}
                className="form-control font-weight-medium stretched-text-xs"
                onChange={v =>
                  setPayload({
                    ...payload,
                    accountNumber: v.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Account Holder&apos;s Name</Label>
              <Input
                type="text"
                value={payload.accountName}
                onChange={e =>
                  setPayload({ ...payload, accountName: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Label>Credit</Label>
            <Switch
              className="ml-5"
              disabled={state.isLoading}
              onChange={v => setPayload({ ...payload, isCredit: v })}
              checked={payload.isCredit}
              checkedIcon={false}
              uncheckedIcon={false}
              height={16}
              width={35}
              handleDiameter={20}
              onHandleColor={'#2688c9'}
              offHandleColor={'#d8d8d8'}
              onColor={'#ffdfd5'}
              offColor={'#b4b3b3'}
            />
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Transaction Date/Time</Label>
              <Datetime
                utc={true}
                value={payload.createdAt}
                onChange={v =>
                  setPayload({ ...payload, createdAt: moment(v).toDate() })
                }
              />
            </FormGroup>
          </Col>
          <Col md={8}>
            <FormGroup>
              <Row>
                <Col md={7}>
                  <Label>Description</Label>
                </Col>
                <Col md={5} className="align-right">
                  <a
                    href="#"
                    className="transparent clickable orange-text"
                    onClick={autoGen}
                  >
                    Auto-Gen
                  </a>
                </Col>
              </Row>

              <Input
                type="textarea"
                className="stretched-text-xs"
                value={payload.description}
                isDisabled={state.isLoading}
                onChange={e =>
                  setPayload({
                    ...payload,
                    description: e.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md={4} className="aligner-container">
            {state.isLoading ? (
              <Loader />
            ) : (
              <Button block color="dark" className="bg-orange">
                Submit
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NewTransactionForm;
