import React, { useContext, useEffect, useState } from 'react';
import { MdCheckCircle, MdEdit, MdError } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Loader } from '../../components/misc';
// import { ACCOUNT_TYPES } from '../../utils/constants/enums';
import { getEntry, NetworkErrorHandler, updateEntry } from '../../utils/dataGenerator';
import NewAccountForm from './NewAccountForm';
import NewTransactionForm from './NewTransactionForm';
import NumberFormat from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import TransactionsTable from '../Account/Transactions/TransactionsTable';
import AccountSecurity from '../Account/Preferences/AccountSecurity';
import Select from 'react-select';
import Enums from "../../constants/enums";
import ActionContext from "../../contexts/ActionContext";

const _ = require('lodash');

const AccountInformation = props => {
  const { user } = props;
  const actionContext = useContext(ActionContext);
  const [showPassMod, togShowPass] = useState(false);
  const [collapsed, setCollapse] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [balances, setBalances] = useState({
    availableBalance: 0,
    lastTransaction: null,
    baseCurrency: user.displayCurrency || 'USD',
    isGetting: true,
  });
  const [restrictions] = useState([
    { label: 'All transfers permitted', value: '700' },
    { label: 'External transfers blocked', value: '701' }, // Only Intra Bank transfers permitted
    { label: 'Internal transfers blocked', value: '702' }, // Only Inter Bank transfers permitted
    { label: 'All transfers blocked', value: '703' },
  ]);
  const [restriction, setRestriction] = useState({
    access: user ? user.access : null,
    access_message: user ? user.access_message : '',
  });
  const [state, setState] = useState({
    isLoading: false
  })

  useEffect(() => {
    if (user.type == Enums.ACCOUNT_TYPES.AH) getAccountSummary();
  }, []);

  const getAccountSummary = () => {
    getEntry(
      `transactions/user/${user._id}?$limit=${Math.pow(10, 6)}`,
      (res, err) => {
        if (!err) {
          const response = res.data;
          // let balance = 0;
          const { transactions } = response;
          const groupedTransactions = _.groupBy(transactions, (trx) => trx.isCredit)
          const totalCredit = _.reduce(groupedTransactions.true || [], (a, b) => a + b.amount, 0)
          const totalDebit = _.reduce(groupedTransactions.false || [], (a, b) => a + b.amount, 0)
          const balance = totalCredit - totalDebit;
          setBalances({
            ...balances,
            availableBalance: balance,
            lastTransaction: _.isEmpty(transactions) ? 0 : transactions[0],
            baseCurrency: user.displayCurrency || 'USD',
            isGetting: false,
          });
          setTransactions(transactions);
          // setState({ ...state, isFetching: false });
        } else {actionContext.showNotification(
          <div className="align-center">
            <MdError style={{ fontSize: 20, color: 'red' }} />
            &nbsp;{NetworkErrorHandler(err)}
          </div>,
          { type: 'error' },
        );
        }
      },
    );
  };

  const saveRestriction = (e) => {
    try {
      e.preventDefault();
      if(!restriction.access) throw Error("Please provide a valid restriction option")      
      if(restriction.access !== '700' && !restriction.access_message) throw Error("Please provide a valid response message")  
      setState({...state, isLoading: true});
      updateEntry(`users/${user._id}`, restriction, (res, err) => {
        if(!err){
          actionContext.showNotification(
            <div className="align-center">
              <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
              &nbsp;Restriction config saved successfully
            </div>,
            { type: 'success' },
          );
          setCollapse(null)
          setState({...state, isLoading: false});
        }else{
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{NetworkErrorHandler(err)}
            </div>,
            { type: 'error' },
          );
        }
      })
    } catch (error) {actionContext.showNotification(
      <div className="align-center">
        <MdError style={{ fontSize: 20, color: 'red' }} />
        &nbsp;{NetworkErrorHandler(error)}
      </div>,
      { type: 'error' },
    );
    }
  }

  return (
    <div>
      <Row>
        <Col md={user.type === Enums.ACCOUNT_TYPES.AH ? 5 : 12}>
          <div className="p-3 p-md-4">
            <h4 className="orange-text title-text">Account Information</h4>
          </div>
          <div className="align-center">
            <div className="thumbnail-lg mx-auto aligner-container shadow-lg">
              <span className="white-text">{user.first_name.substr(0, 1)}</span>
            </div>
            <p style={{ fontSize: 20 }} className="mb-0">{`${user.first_name} ${
              user.last_name
            }`}</p>
            <p className="my-0 grey-text capitalize" style={{ fontSize: 13 }}>
              {user.username}
            </p>
            <p className="my-0 grey-text capitalize" style={{ fontSize: 13 }}>
              {user.type == Enums.ACCOUNT_TYPES.AH
                ? user.accountNumber
                : user.type.replace(/_/g, ' ')}
            </p>
            <Button
              onClick={() => togShowPass(!showPassMod)}
              size="small"
              color="dark"
              className="bg-orange py-2"
            >
              Change Password&nbsp;
              <RiLockPasswordLine />
            </Button>
          </div>
          <hr />
          <Collapse isOpen={showPassMod}>
            <h4 className="orange-text title-text">Change Password</h4>
            <AccountSecurity
              user={user}
              dismiss={() => togShowPass(false)}
              onSuccess={() => togShowPass(false)}
            />
          </Collapse>
          <Collapse isOpen={!showPassMod}>
            <NewAccountForm
              title={'Update Details'}
              isEditing={true}
              user={user}
              dismiss={props.dismiss}
            />
          </Collapse>
        </Col>
        {user.type === Enums.ACCOUNT_TYPES.AH && <Col md={7}>
          {user.type == Enums.ACCOUNT_TYPES.AH && (
            <>
              <div className="p-3 p-md-4">
                <h4 className="orange-text title-text">Transaction</h4>
              </div>
              <Row>
                <Col md={12} className="align-right">
                  <Button
                    size="sm"
                    color="dark"
                    className="clickable bg-dark --with-highlight border-0 my-2"
                    onClick={() =>
                      setCollapse(collapsed == 'newTrx' ? null : 'newTrx')
                    }
                  >
                    New Transaction
                  </Button>
                  <Button
                    size="sm"
                    color="dark"
                    className="clickable bg-orange --with-highlight border-0 ml-2 my-2"
                    onClick={() =>
                      setCollapse(collapsed == 'accRest' ? null : 'accRest')
                    }
                  >
                    Account Restriction
                  </Button>
                </Col>
                <Col md={12}>
                  {balances.isGetting ? (
                    <Loader />
                  ) : (
                    <>
                      <Collapse
                        className="my-5"
                        isOpen={collapsed == 'accRest'}
                      >
                        <div className="p-2 shadow">
                          <h5 className="orange-text title-text">
                            Account Restriction
                          </h5>
                          <Form className="px-md-5" onSubmit={saveRestriction}>
                            <FormGroup>
                              <Label>Restriction</Label>
                              <Select
                                options={restrictions}
                                className={`capitalize`}
                                onChange={val =>
                                  setRestriction({
                                    ...restriction,
                                    access: val.value,
                                    access_message:
                                      val.value == '700'
                                        ? ''
                                        : restriction.access_message,
                                  })
                                }
                                placeholder={function(){
                                  const sel = _.find(restrictions, function(res){ return res.value == restriction.access})
                                  return sel ? sel.label : "Select"
                                }.call()}
                                isSearchable={true}
                              />
                            </FormGroup>
                            {restriction.access !== '700' && (
                              <FormGroup>
                                <Label>Response Message</Label>
                                <Input
                                  type="textarea"
                                  defaultValue={restriction.access_message}
                                  onChange={e =>
                                    setRestriction({
                                      ...restriction,
                                      access_message: e.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            )}
                            <FormGroup>
                              {state.isLoading ? (
                                <Loader />
                              ) : (
                                <Button
                                  block
                                  color="dark"
                                  className="bg-orange"
                                >
                                  Submit
                                </Button>
                              )}
                            </FormGroup>
                          </Form>
                        </div>
                      </Collapse>
                      <Row>
                        <Col md={6}>
                          <h1
                            className="mb-0"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <small className="mr-1">
                              {getSymbolFromCurrency(balances.baseCurrency)}
                            </small>
                            <span style={{ fontSize: 25 }}>
                              <NumberFormat
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={balances.availableBalance}
                                displayType={'text'}
                              />
                            </span>
                          </h1>
                          <span className="stretched-text-sm">
                            Available Balance
                          </span>
                        </Col>
                        {balances.lastTransaction ? (
                          <Col md={6}>
                            <h1
                              className={`mb-0 ${
                                balances.lastTransaction.isCredit
                                  ? 'success-text'
                                  : 'orange-text'
                              }`}
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <small className="mr-1">
                                {!balances.lastTransaction.isCredit && '-'}
                                {getSymbolFromCurrency(
                                  balances.lastTransaction.currency || 'USD',
                                )}
                              </small>
                              <span style={{ fontSize: 25 }}>
                                <NumberFormat
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  value={balances.lastTransaction.amount}
                                  displayType={'text'}
                                />
                              </span>
                            </h1>
                            <span
                              className={`${
                                balances.lastTransaction.isCredit
                                  ? 'success-text'
                                  : 'orange-text'
                              } stretched-text-sm`}
                            >
                              Last Transaction
                            </span>
                          </Col>
                        ) : null}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <Collapse className="mt-2" isOpen={collapsed == 'newTrx'}>
                <div className="p-2 shadow">
                  <NewTransactionForm
                    user={user}
                    onSuccess={() => {
                      setCollapse(null);
                      getAccountSummary();
                    }}
                  />
                </div>
              </Collapse>
            </>
          )}
          {/* transaction history table */}
          <div>
            {!balances.isGetting && (
              <TransactionsTable transactions={transactions} />
            )}
          </div>
        </Col>}
      </Row>
    </div>
  );
};

export default AccountInformation;
