import { Avatar, Box, Button, Container, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from "react";
import Enums from "../constants/enums";
import AccountContext from "../contexts/AccountContext";
import { AlertContext } from "../contexts/AlertContextProvider";
import { AuthContext } from "../contexts/AuthContext";
import API from "../services/api";
import CustomTextField from "./CustomTextField";

export default function LoginForm() {
  const { showError } = useContext(AlertContext);
  const { login, isLoggedIn, authData } = useContext(AuthContext)
  const accountContext = useContext(AccountContext)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState({ accountNumber: "", password: "" })

  const checkSession = () => {
    accountContext.getUser(user => {
      if (user && localStorage.getItem(Enums.STORAGE_KEYS.TOKEN) !== null) {
        setTimeout(() => {
          window.location = `/${user.type == Enums.ACCOUNT_TYPES.AH
            ? 'account'
            : user.type == Enums.ACCOUNT_TYPES.AO || user.type == Enums.ACCOUNT_TYPES.ROOT
              ? 'admin'
              : 'user'
            }/dashboard`;
        }, 2000);
      }
    });
  };

  useEffect(() => {
    checkSession()
  }, [])

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!payload.accountNumber) throw Error("Please provide your account number")
      if (!payload.password) throw Error("Please provide your password")
      setLoading(true)
      await login({ username: payload.accountNumber, password: payload.password })
      setTimeout(() => { checkSession() }, 800);
    } catch (error) {
      setLoading(false)
      showError(error.message)
    }
  };

  const handleOnchange = (event) => {
    setPayload((old) => ({ ...old, [event.target.name]: event.target.value }))
  }

  return (
    <Container maxWidth="sm">
      {!loading && (isLoggedIn && authData) ? <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Avatar sx={{ width: "100px", height: "100px", margin: "auto" }} />
        <Typography sx={{ fontSize: "1.5rem", textAlign: "center" }}>{`${authData?.user?.first_name} ${authData?.user?.last_name}`}</Typography>
        <Button variant="contained" color="success" sx={{ mt: 1, mx: "auto" }} onClick={() => {
          window.location = `/${authData.user.type == Enums.ACCOUNT_TYPES.AH
            ? 'account'
            : authData.user.type == Enums.ACCOUNT_TYPES.AO || authData.user.type == Enums.ACCOUNT_TYPES.ROOT
              ? 'admin'
              : 'user'
            }/dashboard`
        }}>My Dashboard</Button>
      </Box>
        :
        <>
          <Typography variant="h4" align="center" gutterBottom>
            Login
          </Typography>
          <form onSubmit={!loading && handleSubmit} autoComplete={false}>
            <CustomTextField
              fullWidth
              autoComplete={false}
              margin="normal"
              id="accountNumber"
              name="accountNumber"
              label="Account Number"
              variant="filled"
              onChange={handleOnchange}
            />
            {/* <CustomTextField
          required
          fullWidth
          margin="normal"
          id="dateOfBirth"
          label="Date of Birth"
          type="date"
          variant="filled"
          InputLabelProps={{ shrink: true }}
        /> */}
            <CustomTextField
              fullWidth
              margin="normal"
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="filled"
              onChange={handleOnchange}
            />
            <Button type="submit" disabled={loading} fullWidth variant="contained" color="success" sx={{ mt: 3, mb: 2 }}>
              {loading ? "Please wait..." : "Log In"}
            </Button>
          </form>
        </>
      }
    </Container>
  );
}
