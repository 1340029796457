import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import { AuthContext } from '../../contexts/AuthContext';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, useMediaQuery } from "@mui/material";
import Enums from "../../constants/enums";
import AccountContext from "../../contexts/AccountContext";
import "./navbar.css";
import NavMenu from "./NavMenu";

const Navbar = (props) => {
	const navigate = useNavigate();
	const md = useMediaQuery('(max-width:1199px)')
	const { isLoggedIn, authData } = useContext(AuthContext);
	const accountContext = useContext(AccountContext)
	const navMenuRef = React.useRef();

	useEffect(() => {
		const linkItems = document.querySelector("ul")
		linkItems.addEventListener("click", (e) => {
			e.preventDefault()
			switch (e.target.name) {
				case "internet-banking":
					onInternetBanking()
					break;

				default:
					navMenuRef.current.toggleDrawer(true)
					break;
			}
		})

		return () => {
			linkItems.removeEventListener("click", (e) => {
				switch (e.target.name) {
					case "internet-banking":
						e.preventDefault()
						onInternetBanking()
						break;

					default:
						break;
				}
			})
		}
	}, [])

	const onInternetBanking = () => {
		accountContext.getUser(user => {
			if (user && localStorage.getItem(Enums.STORAGE_KEYS.TOKEN) !== null) {
				navigate(`/${user.type == Enums.ACCOUNT_TYPES.AH
					? 'account'
					: user.type == Enums.ACCOUNT_TYPES.AO || user.type == Enums.ACCOUNT_TYPES.ROOT
						? 'admin'
						: 'user'
					}/dashboard`);
			} else {
				navigate("/login");
			}
		});
	};

	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
				<a className="navbar-brand" href="/">
					<Box sx={{ ml: "25px", width: "100px", height: "40px", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain", backgroundImage: `url(${logo})` }} />
				</a>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item active">
							<a className="nav-link px-2" href="#"><MenuIcon />&nbsp;Menu <span className="sr-only">(current)</span></a>
						</li>
						<li className="nav-item"  data-toggle="collapse" data-target="#navbarSupportedContent" >
							<a className="nav-link main-link shadow w-100 px-2" name="internet-banking" href="#"><AccountBalanceIcon />&nbsp;Internet Banking</a>
						</li>
					</ul>
				</div>
			</nav>
			<NavMenu ref={navMenuRef} />
		</>
	)
}

export default Navbar