import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import FingerprintTwoToneIcon from '@mui/icons-material/FingerprintTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import HandingCardImage from "../assets/images/79.jpeg";
import BrandNewLookModal from "../components/BrandNewLookModal";
import ClientsHomeGrid from '../components/ClientsHomeGrid';
import MutualFundTable from '../components/MutualFundTable';
import PrivateBanking from '../components/PrivateBanking';
import { GlassMask } from '../components/styledComponents/MainStyles';

const Landing = () => {
    type TaglineType = { title: string, description: string }
    const taglines: Array<TaglineType> = [
        {
            title: "Total wealth and financial planning",
            description: "We offer financial planning to manage and grow wealth."
        },
        // {
        //     title: "Expert support for family offices",
        //     description: "We provide expert support to families in establishing or developing their own family office for managing their wealth."
        // },
        {
            title: "Protect and grow your assets with our support",
            description: "We help you protect and grow your assets to make a positive impact in your community."
        },
        {
            title: "Safe custody of assets across borders",
            description: "We securely manage assets for businesses across jurisdictions, allowing you to focus on your operations with peace of mind."
        }
    ]
    const tagline: TaglineType = React.useMemo(() => {
        const randomIndex = Math.floor(Math.random() * taglines.length);
        return taglines[randomIndex]
    }, [taglines])

    return <Box>
        {/* BRAND NEW LOOK MODAL */}
        <BrandNewLookModal />
        <Box sx={{
            // backgroundImage: 'linear-gradient(to right, #2688c9, #8ec63f)',
            backgroundImage: `url(${HandingCardImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '80vh',
            opacity: 0.9,
            display: "flex",
            flexDirection: "column",
            // add styling for mobile
            '@media (max-width: 600px)': {
                minHeight: '500px !important',
                overflow: 'hidden',
            }
        }}>
            <GlassMask sx={{ m: 0, borderRadius: "5px", backdropFilter: 'blur(1px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
                <Row style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <Col className='mx-auto' md={7} lg={6} xl={4}>
                        <Typography sx={{ fontSize: "4rem", fontWeight: "900", color: "#FFF", lineHeight: "65px", textShadow: "-1px 0px 2px #333" }}>{tagline.title}</Typography>
                        <Typography sx={{ fontSize: "2rem", color: "#FFF", lineHeight: "45px", textShadow: "-1px 0px 2px #333" }}>{tagline.description}</Typography>
                    </Col>
                    <Col md={4} lg={6} style={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{
                            width: "100%",
                            minHeight: "80vh",
                            padding: "10px",
                            flex: 1,
                            '@media (max-width: 600px)': {
                               display: 'none',
                            }
                        }} />
                        {/* <GlassMask sx={{ m: 1, borderRadius: "5px" }}>
                        <LoginForm />
                    </GlassMask> */}
                    </Col>
                </Row>
            </GlassMask>
        </Box>
        {/* PRIVATE BANKING SECTION */}
        <PrivateBanking />
        {/* INVESTMENT BANKING */}
        <Box sx={{ p: 1, my: 10 }} className="px-2 px-md-5">
            <Row style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <Col className='mx-auto' xl={11} sm={12}>
                    <Typography sx={{ fontSize: "4rem", textAlign: "center", fontWeight: "bold", color: "theme.default" }}>Investment Banking</Typography>
                    <Typography sx={{ textAlign: "justify", my: 1 }}>We offer a range of investment options to help you achieve your financial goals. In today's fast-paced financial markets, investing your money can be time-consuming and risky. That's why we offer our SecureFund and GrowthFund, which allow you to put your money to work for you. It's like having a salary... for life.</Typography>
                    <Typography sx={{ textAlign: "justify", my: 1 }}>Our SecureFund and GrowthFund have delivered impressive returns of 11.75% and 18.25% respectively in the past year, and 14.75% and 23.75% respectively over the past five years. Please note that these returns are not a guarantee of future performance and past performance is not necessarily a guide to future performance. The value of your investment may fluctuate and you may not receive the amount originally invested. We also offer special guaranteed rates of up to 18% annually. To find out more about these rates, please get in touch with us.</Typography>
                </Col>
                <Row>
                    <Col md={6} xl={5} className="mx-auto py-2">
                        <MutualFundTable />
                    </Col>
                    <Col xl={4} md={5} className="mx-auto">
                        <Typography sx={{ textAlign: "justify", my: 1 }}>To make investing in our funds easier for you, we've simplified the process. The minimum investment amount for both funds is £15,000, and there is a front-end load fee of 3%. Flat fee trades are £50 per trade, regardless of the size of your purchase. There is also a 5-7 day redemption period. At Eccount Money, we understand that some investors may prefer to purchase stocks and bonds directly.</Typography>
                    </Col>
                </Row>
                <Col xl={11} sm={12} className="mx-auto">
                    <Typography sx={{ textAlign: "justify", my: 1 }}>That's why we've partnered with a major investment brokerage firm to offer online purchasing of stocks and bonds on all major US and European stock exchanges.</Typography>
                    <Typography sx={{ textAlign: "justify", my: 1 }}>With our Trading Desk, you can purchase investments with ease in the members area of our website. Simply log in to your account, click on the Manage Accounts tab, and select the Investment (Trading) Account button. From there, you'll be able to purchase mutual funds, stocks, and bonds with maximum convenience.</Typography>
                    <Typography sx={{ textAlign: "justify", my: 1 }}>Please note that for stock and bond investments, there is also a front-end load fee of 3%, a flat fee of £50 on all trades, and a minimum investment amount of £15,000. All investments are kept strictly confidential and there is a 5-7 day redemption period.</Typography>
                    <Typography sx={{ textAlign: "justify", my: 1 }}>Investing with Eccount Money gives you access to some of the best investment opportunities available today. We're committed to helping you achieve your financial goals, and our experienced team is always here to help. Contact us today to learn more.</Typography>
                </Col>
            </Row>
        </Box>
        {/* FINANCE */}
        {/* <Box sx={{ p: 1, my: 10 }} className="px-2 px-md-5">
            <ExchangeRatesTable />
        </Box> */}
        {/* WHO WE WORK WITH */}
        <Box sx={{
            backgroundImage: 'linear-gradient(to bottom right, #2688c900, #2688c985, #96ff0254)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '80vh',
            opacity: 0.9,
            display: "flex",
            flexDirection: "column"
        }}>
            <Row style={{ flex: 1, margin: "2rem", display: "flex", alignItems: "center" }}>
                <Col className='mx-auto' md={6} lg={6} xl={4}>
                    <Typography sx={{ fontSize: "4rem", fontWeight: "900", color: "#333", lineHeight: "65px" }}>Partnering with Clients for Financial Success</Typography>
                    <Typography sx={{ fontSize: "2rem", color: "#333", lineHeight: "45px" }}>Who We Work With</Typography>
                    <Typography sx={{ fontSize: "1rem", color: "#333", lineHeight: "25px" }}>At Eccount Money, we work with a diverse range of clients, from individuals and families to family offices, charities and not-for-profit organizations, and intermediaries and corporates.</Typography>
                </Col>
                <Col md={6} className="mx-auto">
                    <ClientsHomeGrid />
                </Col>
            </Row>
        </Box>
        {/* NOTHING TO WORRY ABOUT */}

        <Box sx={{ p: 1, my: 10 }} className="px-2 px-md-5">
            <Row style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <Col className='mx-auto' md={6} lg={6} xl={4}>
                    <Typography sx={{ fontSize: "4rem", textAlign: "center", fontWeight: "900", color: "#333", lineHeight: "65px" }}>You have nothing to worry about</Typography>
                </Col>
            </Row>
            <Row>
                <Col md={12} xl={11} className="mx-auto">
                    <Row style={{ flex: 1, marginTop: "50px" }}>
                        <Col md={3} style={{ height: "100%", marginBottom: "5px" }}>
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                                <Box sx={{ height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
                                    <Box className="shadow" sx={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#ed1b24", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mb: 2 }}><LockTwoToneIcon sx={{ fontSize: "1.5rem", color: "#FFF" }} /></Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>Secured Funds</Typography>
                                </Box>
                                <Typography sx={{ fontSize: "15px" }}>We don't lend out your money and we make sure that it's always secure so you have nothing to worry about.</Typography>
                            </Box>
                        </Col>
                        <Col md={3} style={{ height: "100%", marginBottom: "5px" }}>
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                                <Box sx={{ height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
                                    <Box className="shadow" sx={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#2688c9", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mb: 2 }}><AccountBalanceTwoToneIcon sx={{ fontSize: "1.5rem", color: "#FFF" }} /></Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>Fraud Protection</Typography>
                                </Box>
                                <Typography sx={{ fontSize: "15px" }}>Our AML and security teams are dedicated to keeping your account safe and protected from fraud at all times</Typography>
                            </Box>
                        </Col>
                        <Col md={3} style={{ height: "100%", marginBottom: "5px" }}>
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                                <Box sx={{ height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
                                    <Box className="shadow" sx={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#8ec63f", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mb: 2 }}><FingerprintTwoToneIcon sx={{ fontSize: "1.5rem", color: "#FFF" }} /></Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>Data Security</Typography>
                                </Box>
                                <Typography sx={{ fontSize: "15px" }}>We protect your information with us and it's always secure. We collect and use your information lawfully and transparently.</Typography>
                            </Box>
                        </Col>
                        <Col md={3} style={{ height: "100%", marginBottom: "5px" }}>
                            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", flex: 1 }}>
                                <Box sx={{ height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
                                    <Box className="shadow" sx={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#3200aa", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", mb: 2 }}><ShieldTwoToneIcon sx={{ fontSize: "1.5rem", color: "#FFF" }} /></Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>Secure Transactions</Typography>
                                </Box>
                                <Typography sx={{ fontSize: "15px" }}>Your account is protected with multi-factor authentication to ensure that you alone has access to it.</Typography>
                            </Box>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Box>
    </Box>
}
export default Landing