import React from 'react';
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: '#FFF', // Set the background color of the TextField
      borderRadius: theme?.shape?.borderRadius || 0, // Use the default border radius from the theme
      '&:hover': {
        borderColor: '#6c757d', // Set the border color on hover                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
      },
      '&.Mui-focused': {
        borderColor: '#007bff', // Set the border color when focused
      },
      '&.Mui-error': {
        borderColor: '#dc3545', // Set the border color when in error state
      },
      '& .MuiInputBase-input': {
        color: '#212529', // Set the text color
      },
    },
  },
}));

export default function CustomTextField(props) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      variant="outlined"
      margin="normal"
      {...props}
    />
  );
}                                                                                                                                                                                                                                                                                                                                                                 
