import React, { createContext } from 'react';

const togglePrefSideBar = () => { }
const toggleAccSideBar = () => { }
const openAccount = (user) => { }


const ModalContext = createContext({
    togglePrefSideBar: togglePrefSideBar,
    toggleAccSideBar: toggleAccSideBar,
    openAccount: openAccount,
})

export default ModalContext;