import ContactUs from "../pages/ContacUsPage";
import Landing from "../pages/Landing";
import LoginPage from "../pages/LoginPage";
import Services from "../pages/Services";

export const routes = [
    {
        name: "home",
        path: "/",
        component: Landing
    },
    {
        name: "login",
        path: "/login",
        component: LoginPage

    },
    {
        name: "contact-us",
        path: "/contact-us",
        component: ContactUs

    },
    {
        name: "services",
        path: "/services/:service",
        component: Services
    }
]
