import React from 'react';
import Enums from "../constants/enums";

export const toggleLoader = (v, callback) => {
  const data = JSON.parse(localStorage.getItem(Enums.STORAGE_KEYS.ACTION));
  localStorage.setItem(
    STORAGE_KEYS.ACTION,
    JSON.stringify({ ...data, loaderModal: v }),
  );
  if (callback) return callback();
};

export const persist = data => {
  localStorage.setItem(Enums.STORAGE_KEYS.ACTION, JSON.stringify(data));
};

export const getAction = callback => {
  if (callback)
    return callback(JSON.parse(localStorage.getItem(Enums.STORAGE_KEYS.ACTION)));
};

export const getLoaderState = callback => {
  const data = JSON.parse(localStorage.getItem(Enums.STORAGE_KEYS.ACTION));
  if (callback)
    return callback(data ? data.loaderModal : false);
};

const showLogout = v => {} 
const showNotification = (message, type) => {} 

const ActionContext = React.createContext({
  persist: persist,
  getAction: getAction,
  toggleLoader: toggleLoader,
  getLoaderState: getLoaderState,
  showLogout: showLogout,
  showNotification: showNotification
});

export default ActionContext;
