import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'reactstrap';
import emptyAnimae from '../../assets/lotties/10002-empty-notification.json';
import Footer from "../../components/Footer";
import UserAccountNav from "../../components/Header/UserAccountNav";
import {
  EmptyResult,
  ModalLoader,
  TransactionListItem
} from '../../components/misc';
import AccountContext from "../../contexts/AccountContext";
import { getEntry } from '../../utils/dataGenerator';

const mql = window.matchMedia(`(min-width: 800px)`);
const _ = require('lodash');

const Dashboard = props => {
  const accountContext = React.useContext(AccountContext);
  const [sidebarDocked, setSidebarDocked] = React.useState(true);
  const [transactions, setTransactions] = React.useState([]);
  const [activities, setActivities] = React.useState([]);
  const [exchangeRates, setRates] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [balances, setBalances] = React.useState({
    availableBalance: 0,
    lastTransaction: null,
    baseCurrency: 'EUR',
  });
  const [state, setState] = React.useState({
    isFetching: true,
  });

  const mediaQueryChanged = () => {
    setSidebarDocked(mql.matches);
  };

  React.useEffect(() => {
    mql.addListener(mediaQueryChanged);
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setUser(user);
      getAccountSummary(user);
    });
  }, []);

  const getAccountSummary = (user) => {
    getEntry(`transactions/balance`, (res, err) => {
      if (!err) {
        const response = res.data;
        const { balance, transactions } = response;
        setBalances({
          ...balances,
          availableBalance: balance,
          lastTransaction: _.isEmpty(transactions) ? 0 : transactions[0],
          baseCurrency: user.displayCurrency || 'USD'
        });
        setTransactions(transactions)
        setState({ ...state, isFetching: false });
      } else {
        console.log(err);
      }
    });
  };

  return (
    <>
      <UserAccountNav current="dashboard" sidebarDocked={sidebarDocked}>
        {state.isFetching ? (
          <ModalLoader open={state.isFetching} />
        ) : (
          <div className="mt">
            <div
              style={{
                minHeight: '100vh',
                paddingTop: sidebarDocked ? 50 : 100,
              }}
            >
              <div className="p-3 p-md-4">
                <h4 className="orange-text title-text">Dashboard</h4>
              </div>
              <Row>
                <Col md={8} className="px-2">
                  <section className="bg-grey p-4 p-md-5">
                    <span style={{ fontSize: 23 }}>
                      Hi, {`${user.first_name} ${user.last_name}`}
                    </span>
                    <p
                      className="grey-text stretched-text-xs my-0"
                      style={{ fontSize: 16 }}
                    >
                      {user.accountNumber}</p>
                      <p 
                      className="grey-text stretched-text-xs my-0"
                      style={{ fontSize: 16 }}>
                        <strong>Sort Code:</strong> 60-00-01
                    </p>
                    <Row>
                      <Col md={6}>
                        <h1
                          className="mb-0"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <small className="mr-1">
                            {getSymbolFromCurrency(balances.baseCurrency)}
                          </small>
                          <span style={{ fontSize: 25 }}>
                            <NumberFormat
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={balances.availableBalance}
                              displayType={'text'}
                            />
                          </span>
                        </h1>
                        <span className="stretched-text-sm">
                          Available Balance
                        </span>
                      </Col>
                      {balances.lastTransaction ? (
                        <Col md={6}>
                          <h1
                            className={`mb-0 ${balances.lastTransaction.isCredit ? 'success-text' : 'danger-text'}`}
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <small className="mr-1">
                              {!balances.lastTransaction.isCredit && '-'}
                              {getSymbolFromCurrency(balances.lastTransaction.currency || 'USD')}
                            </small>
                            <span style={{ fontSize: 25 }}>
                              <NumberFormat
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={balances.lastTransaction.amount}
                                displayType={'text'}
                              />
                            </span>
                          </h1>
                          <span className={`${balances.lastTransaction.isCredit ? 'success-text' : 'danger-text'} stretched-text-sm`}>
                            Last Transaction
                          </span>
                        </Col>
                      ) : null}
                    </Row>
                    <Row className="mt-4">
                      <Col
                        md={3}
                        onClick={() => (window.location = '/account/transfers')}
                        className="clickable --with-highlight aligner-container bg-dark py-3 px-2 white-text"
                      >
                        Send Money&nbsp;
                        <BsArrowRight />
                      </Col>
                    </Row>
                  </section>
                  <section className="p-4 p-md-5">
                    <h5 className="stretched-text-sm mb-4">
                      Recent Transactions
                    </h5>
                    {_.isEmpty(transactions) ? (
                      <EmptyResult
                        text={'There are currently no transactions'}
                      />
                    ) : (
                      _.map(transactions, function (transaction, index) {
                        if (index > 5) return null;
                        return <TransactionListItem key={index} transaction={transaction} />;
                      })
                    )}
                  </section>
                </Col>
                <Col md={4} className="px-5 border-left">
                  <h5 className="stretched-text-sm mb-4">Activities</h5>
                  {_.isEmpty(activities) ? (
                    <div className="aligner-container h-100">
                      <EmptyResult
                        text="We didn't find any recent activities"
                        animationData={emptyAnimae}
                      />
                    </div>
                  ) : (
                    _.map([1, 2, 3], function (trasaction, index) {
                      return <TransactionListItem key={index} />;
                    })
                  )}
                </Col>
              </Row>
            </div>
            <Footer />
          </div>
        )}
      </UserAccountNav>
    </>
  );
};

export default Dashboard;
