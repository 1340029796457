import { Box } from "@mui/material";
import React from 'react';
import { Route, Routes, useLocation } from "react-router";
import { routes } from ".";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NotFoundPage from "../pages/NotFoundPage";


const AppRouter = () => {
    const params = useLocation();
    const [showRouter, setShowRouter] = React.useState(false)


    React.useEffect(() => {
        // scroll to page top
        window.scrollTo(0, 0)
        const exclude = ["admin", "account"]
        const isExcluded = exclude.every(route => !params.pathname.includes(route))
        setShowRouter(isExcluded)
    }, [params])

    return (
       showRouter ?  <>
            <Navbar />
            <Box sx={{ minHeight: "calc(100vh - 200px)", marginTop: "60px" }}>
                <Routes>
                    {routes.map((route, i) => {
                        return <Route key={i} path={route.path} element={<route.component />} />
                    })}
                    <Route path={"/*"} element={<NotFoundPage />} />
                </Routes>
            </Box>
            <Footer />
        </>
        :
        <></>
    )
}

export default AppRouter;