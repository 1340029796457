import React, { useContext, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Loader } from '../../../components/misc';
import Enums from "../../../constants/enums";
import ActionContext from "../../../contexts/ActionContext";
import { createEntry, NetworkErrorHandler } from '../../../utils/dataGenerator';

const AccountSecurity = props => {
  const actionContext = useContext(ActionContext);
  const [error, setError] = useState(null);
  const [passwords, setPasswords] = useState({
    old: '',
    new: '',
    con: '',
  });
  const [state, setState] = useState({
    isFetching: false,
  });

  const submit = e => {
    try {
      e.preventDefault();
      if (!Enums.PASSWORD_REGEX.test(passwords.old) && !props.user)
        throw Error('Password must include at least one numeric character, at least one special charater, and any combination of alphabets');
      if (!Enums.PASSWORD_REGEX.test(passwords.new))
        throw Error('Please provide a valid new password');
      if (!Enums.PASSWORD_REGEX.test(passwords.con))
        throw Error('Kindly confirm your password');
      if (!_.isEqual(passwords.con, passwords.new))
        throw Error('Passwords do not match');
      setState({ ...state, isFetching: true });
      createEntry(`auth/${props.user ? `${props.user._id}/` : ''}change-password`, passwords, (res, err) => {
        if(!err){
          const response = res.data;
          actionContext.showNotification(
            <div className="align-center">
              <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
              &nbsp;{response.message}
            </div>,
            { type: 'success' },
          );
          if(props.onSuccess) props.onSuccess()
        }else{
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{NetworkErrorHandler(err)}
            </div>,
            { type: 'error' },
          );
          setError(NetworkErrorHandler(err));
          setTimeout(() => {
            setError(null);
          }, 1500);
        }
        setState({ ...state, isFetching: false });
      })
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{NetworkErrorHandler(error)}
        </div>,
        { type: 'error' },
      );
      setError(NetworkErrorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };
  return (
      <Form onSubmit={submit} className="mx-4 my-3">
        {!props.user && <FormGroup>
          <Label>Old Password</Label>
          <Input
            type="password"
            placeholder="Old Password Here"
            value={passwords.old}
            onChange={e => setPasswords({ ...passwords, old: e.target.value })}
          />
        </FormGroup>}
        <FormGroup>
          <Label>New Password</Label>
          <Input
            type="password"
            placeholder="New Password Here"
            value={passwords.new}
            onChange={e => setPasswords({ ...passwords, new: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Label>Confirm Password</Label>
          <Input
            type="password"
            placeholder="Confirm Password Here"
            value={passwords.con}
            onChange={e => setPasswords({ ...passwords, con: e.target.value })}
          />
        </FormGroup>
        {state.isFetching ? (
          <Loader />
        ) : (
          <FormGroup className="my-3">
            <Button
              onClick={props.dismiss}
              type="button"
              color="dark"
              className="clickable --with-highlight border-0"
            >
              <BsArrowLeft className="mr-3" style={{ fontSize: 20 }} />
              &nbsp;Back
            </Button>
            <Button
              type="submit"
              color="dark"
              className={`bg-orange clickable --with-highlight border-0 ml-1 ${
                error ? 'animated shake' : ''
              }`}
            >
              Proceed&nbsp;
              <BsArrowRight className="ml-3" style={{ fontSize: 20 }} />
            </Button>
          </FormGroup>
        )}
      </Form>
  );
};

export default AccountSecurity;
