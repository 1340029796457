import { Backdrop, Button, CircularProgress, Divider, Typography } from "@mui/material";
import React from 'react';
import { GlassMask } from "./styledComponents/MainStyles";
import Lottie from 'react-lottie';

import newAnimae from '../assets/lotties/2487-new-boutton.json';
import { Col, Row } from "reactstrap";

const BrandNewLookModal = () => {
    const [showNewModal, setShowNewModal] = React.useState(false);

    React.useEffect(() => {
        const hasSeenNewLook = localStorage.getItem("hasSeenNewLook")
        if (hasSeenNewLook !== "SEEN") {
            setTimeout(() => {
                setShowNewModal(true)
            }, 1000);
        }
    }, [])

    const handleClose = () => {
        localStorage.setItem("hasSeenNewLook", "SEEN")
        setShowNewModal(false)
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            transitionDuration={1000}
            open={showNewModal}
        >
            <GlassMask sx={{
                margin: "20%",
                height: "calc(100% - 40px)",
                borderRadius: "5px",
                boxShadow: "none",
                backdropFilter: 'blur(10px)',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                overflowY: "auto",
                minWidth: "400px",
            }}>
                <Row style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <Col md={10} className="mx-auto">
                        <Typography sx={{ fontSize: "2.5rem", textAlign: "center", fontWeight: "900", color: "#FFF", lineHeight: "50px", textShadow: "-1px 0px 2px #333" }}>WE HAVE UPDATED OUR SECURITY FEATURES</Typography>
                        <Divider sx={{ marginY: "10px" }} />
                        <Typography sx={{ fontSize: "2rem", fontWeight: "600", color: "#FFF", lineHeight: "45px", textShadow: "-1px 0px 2px #333" }}>To help keep your account safe and secure we have introduced additional security features.</Typography>
                        <Typography sx={{ fontSize: "1.5rem", marginTop: "20px", color: "#FFF", lineHeight: "35px", textShadow: "-1px 0px 2px #333" }}>
                            We use cookies and other tracking technologies to improve your experience on our website and
                            to personalise the website contents. To continue, you must accept the use of cookies in line with our cookie policy.
                            <br /><br /><strong>NOTE:</strong> No tracking technology is used within Online Banking.</Typography>
                        <Button onClick={handleClose} variant="contained" color="success" sx={{ mt: 2 }}>Continue</Button>
                    </Col>
                </Row>

            </GlassMask>
        </Backdrop>
    )
}
export default BrandNewLookModal