import React, { useContext, useEffect, useState } from 'react';
import { FcPlus } from 'react-icons/fc';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { MdCheckCircle, MdError } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row
} from 'reactstrap';
import { Loader } from '../../components/misc';
import Enums from "../../constants/enums";
import AccountContext from "../../contexts/AccountContext";
import ActionContext from "../../contexts/ActionContext";
import {
  createEntry,
  NetworkErrorHandler,
  OTP_GEN,
  updateEntry
} from '../../utils/dataGenerator';
import _ from "lodash"
import { Box } from "@mui/material";

const { BroadcastChannel } = require('broadcast-channel');
const channel = new BroadcastChannel(Enums.BROADCAST_CHANNELS.ACCOUNTS);

const NewAccountForm = props => {
  const accountContext = useContext(AccountContext);
  const actionContext = useContext(ActionContext);
  const [user, setUser] = useState(null);
  const [secureEntry, setSecureEntry] = useState(true);
  const [secureEntry2, setSecureEntry2] = useState(true);
  const [accountTypes, setAccountTypes] = useState([
    Enums.ACCOUNT_TYPES.AO,
    Enums.ACCOUNT_TYPES.AH,
  ]);
  const [account, setAccount] = useState(
    props.user || {
      first_name: '',
      last_name: '',
      accountNumber: OTP_GEN(10),
      type: null,
      email: '',
      password: '',
      username: '',
      PIN: '',
      displayCurrency: null,
    },
  );
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    isFetching: false,
  });

  useEffect(() => {
    accountContext.getUser(user => {
      setUser(user);
      if (user.type == Enums.ACCOUNT_TYPES.ROOT)
        setAccountTypes(_.uniq([
          ...accountTypes,
          Enums.ACCOUNT_TYPES.ROOT,
          Enums.ACCOUNT_TYPES.AO,
        ]));
    });
  }, []);

  const createAccount = e => {
    try {
      e.preventDefault();
      if (!account.first_name)
        throw Error("Please provide the user's first name");
      if (!account.last_name)
        throw Error("Please provide the user's last name");
      if (!account.type) throw Error('Kindly select an account type');
      if (account.type == Enums.ACCOUNT_TYPES.AH && !account.displayCurrency)
        throw Error('Kindly assign a display currency for this account');
      if (account.type == Enums.ACCOUNT_TYPES.AH && !account.accountNumber)
        throw Error('Please provide an account number');
      if (account.type == Enums.ACCOUNT_TYPES.AH && !Enums.PIN_REGEX.test(account.PIN))
        throw Error(
          'Invalid PIN combination. PIN must be four digits long and must contain only numeric characters',
        );
      if (!account.email) throw Error('Kindly provide an email address');
      if (!account.username) throw Error('Kindly provide a username');
      if (!Enums.PASSWORD_REGEX.test(account.password))
        throw Error(
          'Password must include at least one numeric character, at least one special charater, and any combination of alphabets',
        );

      setState({ ...state, isFetching: true });
      createEntry(`auth/register`, account, (res, err) => {
        if (!err) {
          channel.postMessage('REFRES_ACCOUNT');
          actionContext.showNotification(
            <div className="align-center">
              <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
              &nbsp;New user account created successfully
            </div>,
            { type: 'success' },
          );
          props.dismiss();
        } else {
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{NetworkErrorHandler(err)}
            </div>,
            { type: 'error' },
          );
          setError(NetworkErrorHandler(err));
          setTimeout(() => {
            setError(null);
          }, 1500);
        }
        setState({ ...state, isFetching: false });
      });
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{NetworkErrorHandler(error)}
        </div>,
        { type: 'error' },
      );
      setError(NetworkErrorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  const updateAccount = e => {
    try {
      e.preventDefault();
      if (!account.first_name)
        throw Error("Please provide the user's first name");
      if (!account.last_name)
        throw Error("Please provide the user's last name");
      if (!account.type) throw Error('Kindly select an account type');
      if (account.type == Enums.ACCOUNT_TYPES.AH && !account.displayCurrency)
        throw Error('Kindly assign a display currency for this account');
      if (account.type == Enums.ACCOUNT_TYPES.AH && !account.accountNumber)
        throw Error('Please provide an account number');
      if (account.type == Enums.ACCOUNT_TYPES.AH && !Enums.PIN_REGEX.test(account.PIN))
        throw Error(
          'Invalid PIN combination. PIN must be four digits long and must contain only numeric characters',
        );
      if (!account.email) throw Error('Kindly provide an email address');
      if (!account.username) throw Error('Kindly provide a username');
      setState({ ...state, isFetching: true });
      updateEntry(`users/${account._id}`, account, (res, err) => {
        if (!err) {
          channel.postMessage('REFRES_ACCOUNT');
          actionContext.showNotification(
            <div className="align-center">
              <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
              &nbsp;User account updated successfully
            </div>,
            { type: 'success' },
          );
        } else {
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{NetworkErrorHandler(err)}
            </div>,
            { type: 'error' },
          );
          setError(NetworkErrorHandler(err));
          setTimeout(() => {
            setError(null);
          }, 1500);
        }
        setState({ ...state, isFetching: false });
      });
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{NetworkErrorHandler(error)}
        </div>,
        { type: 'error' },
      );
      setError(NetworkErrorHandler(error));
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: '100vh',
        }}
      >
        <div className="p-3 p-md-4">
          <h4 className="orange-text title-text">
            {props.title || 'New Account'}
          </h4>
        </div>
        <Form onSubmit={props.isEditing ? updateAccount : createAccount}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  defaultValue={account.first_name}
                  onChange={e =>
                    setAccount({ ...account, first_name: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  defaultValue={account.last_name}
                  onChange={e =>
                    setAccount({ ...account, last_name: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            {user && (
              <Col md={6}>
                <FormGroup>
                  <Label>User Account Type</Label>
                  <Select
                    isDisabled={state.isFetching}
                    isLoading={state.gettingCurrencies}
                    options={_.map(accountTypes, function (con) {
                      return {
                        label: con.replace(/_/g, ' '),
                        value: con,
                      };
                    })}
                    className={`capitalize`}
                    onChange={val =>
                      setAccount({ ...account, type: val.value })
                    }
                    isSearchable={true}
                    placeholder={
                      account.type ? account.type.replace(/_/g, ' ') : 'Select'
                    }
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={6}>
              <FormGroup>
                <Label>Default Currency</Label>
                <Select
                  isDisabled={
                    state.isFetching ||
                    (account.type == Enums.ACCOUNT_TYPES.ROOT ||
                      account.type == Enums.ACCOUNT_TYPES.AO)
                  }
                  isLoading={state.gettingCurrencies}
                  options={_.map(Enums.CURRENCIES, function (con) {
                    return {
                      ...con,
                      value: con.code,
                      label: `${con.name} - ${con.code} (${con.symbol})`,
                    };
                  })}
                  className={`capitalize`}
                  onChange={val =>
                    setAccount({ ...account, displayCurrency: val.value })
                  }
                  isSearchable={true}
                  placeholder={
                    account.type ? account.displayCurrency : 'Select'
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Account Number</Label>
                <NumberFormat
                  decimalScale={0}
                  fixedDecimalScale={true}
                  value={account.accountNumber}
                  displayType={'input'}
                  className="form-control"
                  onChange={v =>
                    setAccount({
                      ...account,
                      accountNumber: v.target.value,
                    })
                  }
                  disabled={
                    state.isFetching ||
                    (account.type == Enums.ACCOUNT_TYPES.ROOT ||
                      account.type == Enums.ACCOUNT_TYPES.AO)
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className={`animated`}>
                <Label>PIN</Label>
                <InputGroup>
                  <Input
                    type={secureEntry2 ? 'password' : 'text'}
                    defaultValue={account.PIN}
                    maxLength={4}
                    onChange={e =>
                      setAccount({ ...account, PIN: e.target.value })
                    }
                    placeholder="1234"
                    disabled={
                      state.isFetching ||
                      (account.type == Enums.ACCOUNT_TYPES.ROOT ||
                        account.type == Enums.ACCOUNT_TYPES.AO)
                    }
                  />
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#f3f3f3", width: "30px" }}
                    onClick={() => setSecureEntry2(!secureEntry2)}
                    className="align-vertical-center clickable px-2"
                  >
                    {secureEntry2 ? <FiEyeOff /> : <FiEye />}
                  </Box>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>E-mail</Label>
            <Input
              type="email"
              defaultValue={account.email}
              onChange={e => setAccount({ ...account, email: e.target.value })}
              disabled={state.isFetching}
            />
          </FormGroup>
          <Row>
            <Col md={props.isEditing ? 12 : 6}>
              <FormGroup>
                <Label>Username</Label>
                <Input
                  type="text"
                  defaultValue={account.username}
                  onChange={e =>
                    setAccount({ ...account, username: e.target.value })
                  }
                  disabled={state.isFetching}
                />
              </FormGroup>
            </Col>
            {!props.isEditing && (
              <Col md={6}>
                <Label>Password</Label>
                <FormGroup className={`animated`}>
                  <InputGroup>
                    <Input
                      type={secureEntry ? 'password' : 'text'}
                      defaultValue={account.password}
                      onChange={e =>
                        setAccount({ ...account, password: e.target.value })
                      }
                      placeholder="Password"
                    />
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#f3f3f3", width: "30px" }}
                      onClick={() => setSecureEntry(!secureEntry)}
                      className="align-vertical-center clickable px-2"
                    >
                      {secureEntry ? <FiEyeOff /> : <FiEye />}
                    </Box>
                  </InputGroup>
                </FormGroup>
              </Col>
            )}
          </Row>
          <FormGroup className="align-center">
            {state.isFetching ? (
              <Loader />
            ) : (
              <Button
                type="submit"
                color="dark"
                className={`${error ? 'animated shake' : ''}`}
              >
                <FcPlus style={{ fontSize: 20 }} />
                &nbsp;
                {props.isEditing ? 'Update Account' : 'Create Account'}
              </Button>
            )}
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default NewAccountForm;
