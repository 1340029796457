import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type AlertContextType = {
  showError: (message: string) => void;
  showAlert: (type: 'info' | 'success' | 'warning' | 'error', message: string) => void;
};

export const AlertContext = React.createContext<AlertContextType>({
  showError: () => {},
  showAlert: () => {},
});

function AlertContextProvider(props: { children: React.ReactNode }) {
  const { children } = props;

  const showError = (message: string) => {
    toast.error(message);
  };

  const showAlert = (type: 'info' | 'success' | 'warning' | 'error', message: string) => {
    toast[type](message);
  };

  return (
    <>
      <AlertContext.Provider value={{ showError, showAlert }}>
        {children}
        <ToastContainer />
      </AlertContext.Provider>
    </>
  );
}

export default AlertContextProvider;
