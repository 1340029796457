import { Box } from "@mui/material";
import React, { useState } from 'react';
import { Route, Routes, useLocation } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../components/Footer";
import { SideModal } from "../components/misc";
import Navbar from "../components/Navbar";
import ActionContext from "../contexts/ActionContext";
import ModalContext from "../contexts/ModalContext";
import Preferences from "../pages/Account/Preferences";
import AccountInformation from "../pages/backOffice/AccountInformation";
import AdminDashboard from "../pages/backOffice/AdminDashboard";
import NewAccountForm from "../pages/backOffice/NewAccountForm";
import NotFoundPage from "../pages/NotFoundPage";
import useSound from "use-sound";
import Header from "../components/Header";
import GlobalStyle from "../global-styles";
import Enums from "../constants/enums";
import Dashboard from "../pages/Account/Dashboard";
import Transfers from "../pages/Account/Transfers";
import Transactions from "../pages/Account/Transactions";

const errorFx = require("../assets/sounds/320181_dland_hint.mp3")
const successFx = require('../assets/sounds/success_notification_alert_A_major.mp3');

const routes = [
    {
        name: "account",
        path: "/account/",
        component: Dashboard
    },
    {
        name: "account",
        path: "/account/dashboard",
        component: Dashboard
    },
    {
        name: "transfers",
        path: "/account/transfers",
        component: Transfers
    },
    {
        name: "transactions",
        path: "/account/transactions",
        component: Transactions
    },
]


const AccountRouter = () => {
    const params = useLocation();
    const [showRouter, setShowRouter] = React.useState(false)

    const [showPrefSide, setShowPrefSide] = React.useState(false);
    const [showAccInfoSide, setShowAccInfoSide] = React.useState(null);
    const [showNewAccSide, setShowNewAccSide] = React.useState(false);
    const [playErrorFx, errorFxOptions] = useSound(errorFx);
    const [playSuccessFx, successFxOptions] = useSound(successFx);

    const notify = (message, options) => {
        if (errorFxOptions.isPlaying) errorFxOptions.stop();
        if (successFxOptions.isPlaying) successFxOptions.stop();
        switch (options.type) {
            case 'success':
                playSuccessFx();
                break;

            default:
                playErrorFx();
                break;
        }
        toast.dark(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    React.useEffect(() => {
        // scroll to page top
        window.scrollTo(0, 0)
        setShowRouter(params.pathname.split("/").includes("account"))
    }, [params])

    return (
        showRouter ?
            <>

                <ModalContext.Provider value={{
                    togglePrefSideBar: () => setShowPrefSide(!showPrefSide),
                    toggleAccSideBar: () => setShowNewAccSide(!showNewAccSide),
                    openAccount: (user) => setShowAccInfoSide(user),
                }}>
                    <ActionContext.Provider
                        value={{
                            showNotification: (m, t) => notify(m, t)
                        }}
                    >

                        <Header />
                        <Box sx={{ minHeight: "calc(100vh - 200px)", marginTop: "60px" }}>
                            <Routes>
                                {routes.map((route, i) => {
                                    return <Route key={i} path={route.path} element={<route.component />} />
                                })}
                                <Route path={"/*"} element={<NotFoundPage />} />
                            </Routes>
                        </Box>
                        {/* <Footer /> */}

                        <ToastContainer />
                        {showPrefSide && (
                            <SideModal
                                dismiss={() => setShowPrefSide(false)}
                                animation={'slideInRight'}
                            >
                                <Preferences />
                            </SideModal>
                        )}
                        {showNewAccSide && (
                            <SideModal
                                dismiss={() => setShowNewAccSide(false)}
                                animation={'slideInRight'}
                            >
                                <NewAccountForm dismiss={() => setShowNewAccSide(false)} />
                            </SideModal>
                        )}
                        {showAccInfoSide && (
                            <SideModal
                                dismiss={() => setShowAccInfoSide(null)}
                                animation={'slideInRight'}
                                size={showAccInfoSide?.type == Enums.ACCOUNT_TYPES.AH ? 11 : 6}
                            >
                                <AccountInformation
                                    user={showAccInfoSide}
                                    dismiss={() => setShowAccInfoSide(null)}
                                />
                            </SideModal>
                        )}
                    </ActionContext.Provider>
                </ModalContext.Provider>
			<GlobalStyle />
            </>
            :
            <></>
    )
}

export default AccountRouter;