import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from 'react';
import Enums from "../../constants/enums";
import AccountContext from "../../contexts/AccountContext";
import ActionContext from "../../contexts/ActionContext";
import ModalContext from "../../contexts/ModalContext";

import DashboardIcon from '@mui/icons-material/Dashboard';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SegmentIcon from '@mui/icons-material/Segment';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TuneIcon from '@mui/icons-material/Tune';

const DrawerLink = ({ className, onClick, title, iconComponent, active, open }) => {

  return (
    <ListItem className={className} disablePadding sx={{ display: 'block' }} onClick={onClick}>
      <ListItemButton
        disabled={active}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {iconComponent}
        </ListItemIcon>
        <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}


const SidebarLinks = props => {
  const { open } = props;
  const actionContext = React.useContext(ActionContext);
  const accountContext = React.useContext(AccountContext);
  const modalContext = React.useContext(ModalContext);
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    accountContext.getUser(user => setUser(user));
  }, []);

  return (!user ? null :
    <div className="sidebar-container">
      <ul className="list-group">
        <DrawerLink
          open={open}
          title="Dashboard"
          iconComponent={<DashboardIcon />}
          active={props.current == 'dashboard'}
          onClick={() =>
          (window.location = `/${user.type == Enums.ACCOUNT_TYPES.AO || user.type == Enums.ACCOUNT_TYPES.ROOT
            ? 'admin'
            : 'account'
            }/dashboard`)
          }
        />

        {user.type == Enums.ACCOUNT_TYPES.AH &&
          <>

            {/* transfers */}
            <DrawerLink
              open={open}
              title="Transfers"
              iconComponent={<SwapHorizIcon />}
              active={props.current == 'transfers'}
              onClick={() => (window.location = '/account/transfers')}
            />
            {/* transactions */}

            <DrawerLink
              open={open}
              title="Transactions"
              iconComponent={<SegmentIcon />}
              active={props.current == 'transactions'}
              onClick={() => (window.location = '/account/transactions')}
            />
          </>
        }

        {/* preferences */}
        <DrawerLink
          open={open}
          title="Preferences"
          iconComponent={<TuneIcon />}
          active={props.current == 'preferences'}
          onClick={() => modalContext.togglePrefSideBar()}
        />
        <Divider />

        <DrawerLink
          open={open}
          title="Log Out"
          className={`pro clickable`}
          iconComponent={<PowerSettingsNewIcon />}
          onClick={() => actionContext.showLogout(true)}
        />
      </ul>
    </div>
  );
};

export default SidebarLinks;
