import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
// import logo from "./assest/image/logoLarge.png";
import { useNavigate } from "react-router-dom";
import { GlassMask } from "./styledComponents/MainStyles";
import { Typography } from "@mui/material";
import moment from "moment";

const FooterWrapper = styled.div`
    background-image: linear-gradient(to right, #2688c9, #8ec63f);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.9,

    .col-md-8 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 46px;
    }

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    img {
        width: 200px;
        margin-left: auto;
        cursor: pointer;
    }

    .copyright {
        margin-top: 10px;
        padding: 20px;
        border-top: .5px solid #333;
        text-align: center;

        small {
            color: #333;
        }
    }
`

const Footer = () => {
    const navigate = useNavigate()

    return (
        <FooterWrapper id="site-footer">
            <GlassMask sx={{ boxShadow: "none" }}>
                <Row>
                    <Col md={12}>
                        <Typography sx={{ fontSize: "13px", color: "#333" }}>The Eccount Money VISA Card is issued by IDT Financial Services Limited pursuant to licence from Visa Europe. IDT Financial Services Limited is a regulated bank, licensed by the Financial Services Commission, Gibraltar. Registered Office: 57-63 Line Wall Road, Gibraltar. Registered No. 95716. All communications should be sent to Eccount Money, PO Box 3753, Chester, CH1 9UH.</Typography>
                    </Col>
                </Row>
                <div className='copyright'>
                <Typography sx={{ fontSize: "13px", color: "#333" }}>&copy; {moment().format("YYYY")} All Rights Reserved</Typography>
                </div>
            </GlassMask>
        </FooterWrapper>
    )
}

export default Footer;