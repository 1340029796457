import React from 'react';
import {
  Navbar,
  NavbarBrand
} from 'reactstrap';
import NavMenu from "../Navbar/NavMenu";
import MenuIcon from '@mui/icons-material/Menu';

const TopNavbar = props => {
	const navMenuRef = React.useRef();

  return (
    <>
      <Navbar light expand="md" className="navbar fixed-top bg-white shadow">
        <NavbarBrand href="/">
          <img
            src={require('../../assets/images/logo.png')}
            style={{ width: 80 }}
          />
        </NavbarBrand>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#" onClick={() => navMenuRef.current.toggleDrawer(true)}><MenuIcon />&nbsp;Menu <span class="sr-only">(current)</span></a>
            </li>
          </ul>
        </div>
      </Navbar>
      <NavMenu ref={navMenuRef} />
    </>
  );
};

export default TopNavbar;
