import { Box } from "@mui/material";
import * as React from 'react';
import { Col, Row } from "reactstrap";
import LoginForm from "../components/LoginForm";
import PrivateBanking from "../components/PrivateBanking";
import { GlassMask } from "../components/styledComponents/MainStyles";

const LoginPage = () => {
    return (
        <div>
            <Box sx={{
                backgroundImage: 'linear-gradient(to right, #2688c9, #8ec63f)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '80vh',
                opacity: 0.9,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <Row>
                    <Col md={4} lg={6} className="mx-auto" style={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", height: "100%", padding: "10px", flex: 1, }} />
                        <GlassMask sx={{ m: 1, borderRadius: "5px" }}>
                            <LoginForm />
                        </GlassMask>
                    </Col>
                </Row>
            </Box>
            <PrivateBanking />
        </div>
    );
}
export default LoginPage;