import React from "react";
import { useForm } from "react-hook-form";
import { Container, TextField, FormControl, InputLabel, MenuItem, Select, Button, Typography, Divider, Grid } from "@mui/material";

function ContactUs() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        reset();
    };

    return (
        <Container maxWidth="sm" sx={{ marginY: "70px" }}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Get In Touch With Us
            </Typography>

            <Divider sx={{ mb: 3 }} />
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                ECCOUNT MONEY GIBRALTAR
            </Typography>
            <Typography>
                IF YOU REQUIRE SPECIFIC ASSISTANCE, PLEASE CONTACT YOUR PERSONAL ECCOUNT MONEY ACCOUNT RELATIONSHIP MANAGER.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 3 }}>
                        <strong>EMAIL ENQUIRY</strong><br />info@eccount-eccountplatform.com
                    </Typography>
                    <Typography sx={{ mt: 3 }}>
                        <strong>OFFICE</strong><br />57-63 Line Wall Road, Gibraltar. Registered No. 95716.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography  sx={{ mt: 3 }}>
                        <strong>CUSTOMER SERVICE</strong>
                    </Typography>
                    <Typography>Call Your Account Officer For Assistance</Typography>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel htmlFor="department">Department</InputLabel>
                    <Select
                        {...register("department", { required: true })}
                        label="Department"
                        id="department"
                        error={!!errors.department}
                    >
                        <MenuItem value="">
                            <em>Select a Department</em>
                        </MenuItem>
                        <MenuItem value="Information">Information</MenuItem>
                        <MenuItem value="Personal Banking">Personal Banking</MenuItem>
                        <MenuItem value="Private Banking">Private Banking</MenuItem>
                        <MenuItem value="Business Banking">Business Banking</MenuItem>
                        <MenuItem value="Internet Banking">Internet Banking</MenuItem>
                        <MenuItem value="Help">Help</MenuItem>
                    </Select>
                    {errors.department && (
                        <Typography variant="caption" color="error">
                            Please select a department.
                        </Typography>
                    )}
                </FormControl>

                <TextField
                    {...register("name", { required: true })}
                    fullWidth
                    variant="outlined"
                    label="Name"
                    id="name"
                    margin="normal"
                    error={!!errors.name}
                    helperText={errors.name && "Name is required."}
                />

                <TextField
                    {...register("email", { required: true })}
                    fullWidth
                    variant="outlined"
                    label="Email"
                    id="email"
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email && "Email is required."}
                />

                <TextField
                    {...register("phone", { required: true })}
                    fullWidth
                    variant="outlined"
                    label="Phone"
                    id="phone"
                    margin="normal"
                    error={!!errors.phone}
                    helperText={errors.phone && "Phone is required."}
                />

                <TextField
                    {...register("comments", { required: true })}
                    fullWidth
                    variant="outlined"
                    label="Comments"
                    id="comments"
                    multiline
                    rows={4}
                    margin="normal"
                    error={!!errors.comments}
                    helperText={errors.comments && "Comments are required."}
                />

                <Button variant="contained" color="success" type="submit" fullWidth sx={{ mt: 2 }}>
                    Submit
                </Button>
            </form>
        </Container>
    );
}

export default ContactUs;
