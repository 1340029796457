import React, { useContext, useState } from 'react';
import {
  BsArrowReturnLeft,
  BsArrowReturnRight,
  BsArrowRight,
} from 'react-icons/bs';
import { MdCheckCircle } from 'react-icons/md';
import Lottie from 'react-lottie';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import loaderAnimae from '../assets/lotties/kite_loader.json';
import emptyAnimae from '../assets/lotties/16656-empty-state.json';
import OtpInput from 'react-otp-input';
import { MdError } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import ReactWOW from 'react-wow';
import { RiCloseLine } from 'react-icons/ri';
import ActionContext from "../contexts/ActionContext";
import AccountContext from "../contexts/AccountContext";
import { Backdrop, Box } from "@mui/material";

const _ = require('lodash');

export const EmptyResult = props => {
  const animationData = props.animationData || emptyAnimae;
  return (
    <div className="p-5 align-center">
      {animationData && <Lottie
        options={{
          loop: props.loop || false,
          autoplay: true,
          animationData
        }}
        style={props.lottieStyle || { height: 150 }}
      />}
      <p className="stretched-text-xs">{props.text}</p>
    </div>
  );
};

export const Loader = props => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: props.animationData || loaderAnimae,
      }}
      style={props.lottieStyle || { height: 50 }}
    />
  );
};

export const ModalLoader = props => {
  return (
    <Modal
      size={'md'}
      fade={false}
      toggle={props.toggle}
      isOpen={props.open}
      backdropClassName={'bg-white'}
      className="modal-dialog-centered loader-modal"
    >
      <ModalBody className="transparent shadow-none align-center border-0">
        <Box>
          <Loader lottieStyle={{ height: 150 }} />
        </Box>
      </ModalBody>
    </Modal>
  );
};

export const LogoutModal = props => {
  return (
    <Modal
      size={'md'}
      fade={false}
      toggle={props.toggle}
      isOpen={props.open}
      // backdropClassName={'bg-white'}
      className="modal-dialog-centered"
    >
      <ModalHeader
        className="bg-light stretched-text-xs"
        style={{ textTransform: 'uppercase' }}
      >
        Log out Confirmation
      </ModalHeader>
      <ModalBody className="border-0 p-4">
        <h5 className="stretched-text-xs">Are you sure you want to log out?</h5>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button
          onClick={props.confirm}
          color="dark"
          className="bg-dark white-text clickable --with-highlight"
        >
          Log Out
        </Button>
        <Button
          onClick={props.toggle}
          color={'light'}
          className="bg-light dark-text"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const MiscModal = props => {
  return (
    <Modal
      size={'md'}
      fade={false}
      toggle={props.toggle}
      isOpen={props.open}
      backdropClassName={'bg-white'}
      className="modal-dialog-centered"
    >
      <ModalHeader
        className="bg-light stretched-text-xs"
        style={{ textTransform: 'uppercase' }}
      >
        {props.title}
      </ModalHeader>
      <ModalBody className="border-0 p-4">{props.children}</ModalBody>
    </Modal>
  );
};

export const TransactionListItem = props => {
  const [hover, setHover] = React.useState(false);
  const { transaction } = props;
  return (
    <Row
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`clickable --with-highlight bg-white my-2 ${hover ? 'shadow-lg' : 'shadow-none'
        }`}
    >
      <Col
        xs={1}
        className={`aligner-container ${transaction.isCredit ? 'bg-success' : 'bg-danger'
          }`}
      >
        {transaction.isCredit ? (
          <BsArrowReturnRight style={{ fontSize: 25 }} className="white-text" />
        ) : (
          <BsArrowReturnLeft style={{ fontSize: 25 }} className="white-text" />
        )}
      </Col>
      <Col xs={8} className="py-3 px-2">
        <span className="mb-0 grey-ic">
          {transaction.accountName && `${transaction.accountName}`}
          &nbsp;{transaction.accountNumber && ` - ${transaction.accountNumber}`}
        </span>
        <p className="mb-0 stretched-text-xs">{transaction.description}</p>
      </Col>
      <Col xs={3} className="aligner-container">
        <strong>
          {getSymbolFromCurrency(transaction.currency)}&nbsp;
          <NumberFormat
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            value={transaction.amount}
            displayType={'text'}
            className="stretched-text-xs"
          />
        </strong>
      </Col>
    </Row>
  );
};

export const OTPInputModal = props => {
  const len = 4;
  const [otp, setOtp] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const actionContext = useContext(ActionContext);
  const accountContext = useContext(AccountContext);

  const confirm = () => {
    accountContext.getUser(user => {
      try {
        if (_.size(otp) < len) throw Error(`The OTP provided is invalid`);
        if (!_.isEqual(otp, user.PIN))
          throw Error('Invalid transaction PIN provided');
        setLoading(true);
        setTimeout(() => {
          if (props.successCallback) props.successCallback();
          props.toggle();
        }, 2000);
      } catch (error) {
        actionContext.showNotification(
          <div className="align-center">
            <MdError style={{ fontSize: 20, color: 'red' }} />
            &nbsp;{error.message}
          </div>,
          { type: 'error' },
        );
        setError(error.message);
        setTimeout(() => {
          setError(null);
          setOtp(null);
        }, 1500);
      }
    });
  };

  return (
    <Modal
      size={'md'}
      fade={false}
      toggle={props.toggle}
      isOpen={props.open}
      backdropClassName={'bg-white'}
      className="modal-dialog-centered"
    >
      <ModalHeader
        className="bg-light stretched-text-xs"
        style={{ textTransform: 'uppercase' }}
      >
        PIN Confirmation
      </ModalHeader>
      <ModalBody className="border-0 p-4 align-center">
        <span className="stretched-text-xs">
          Please enter your transaction PIN
        </span>
        <div className={`my-4 ${error ? 'animated shake' : ''}`}>
          {loading ? (
            <Loader />
          ) : (
            <OtpInput
              value={otp}
              onChange={otp => setOtp(otp)}
              numInputs={len}
              isInputSecure={true}
              shouldAutoFocus={true}
              hasErrored={error}
              containerStyle={{ width: '100%', justifyContent: 'center' }}
              inputStyle={{ fontSize: 18, height: 40, width: 40, margin: 5 }}
              errorStyle={{ borderColor: '#2688c9' }}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter className="align-right">
        <Button
          onClick={confirm}
          color="dark"
          className="bg-dark white-text clickable --with-highlight"
        >
          Confirm
        </Button>
        <Button
          onClick={props.toggle}
          color={'light'}
          className="bg-light dark-text"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const SideModal = props => {
  const [animation, setAnimation] = useState('');
  const dismiss = () => {
    // setAnimation('slideOutRight');
    // setTimeout(() => {
    props.dismiss();
    // }, 1500);
  };
  return (
    // <ReactWOW animation={props.animation} duration={'1s'}>
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      // onClick={dismiss}
    >
      <Row>
        <Col
          md={props.size || 5}
          className={`side-modal ml-auto bg-white shadow-lg animated ${animation}`}
        >
          <div
            onClick={() => dismiss()}
            style={{ width: 'fit-content' }}
            className="transparent ml-auto clickable p-2 ml-auto"
          >
            <BsArrowRight className="midnight-text" style={{ fontSize: 20 }} />
          </div>
          {props.children}
        </Col>
      </Row>
    </Backdrop>
    // </ReactWOW>
  );
};

export const TransactionSuccess = props => {
  const { data, dismiss } = props;
  return (
    // <ReactWOW animation={'zoomIn'} duration={'1s'}>
    <>
      <div>
        <EmptyResult
          text={
            <div>
              <MdCheckCircle style={{ fontSize: 50, color: 'green' }}  />
              <p className="stretched-text-xs" style={{ fontSize: 20 }}>
                Transaction Successful
              </p>
              <Form>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="stretched-text-xs">
                        Beneficiary Name
                      </Label>
                      <p className="stretched-text-xs">{data.accountName}</p>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="stretched-text-xs">
                        Account Number
                      </Label>
                      <p className="stretched-text-xs">{data.accountNumber}</p>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="stretched-text-xs">Bank Name</Label>
                      <p className="stretched-text-xs">{data.bank}</p>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="stretched-text-xs">Amount</Label>
                      <p className="stretched-text-xs">
                        {getSymbolFromCurrency(data.currency)}&nbsp;
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={data.amount}
                          displayType={'text'}
                        />
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <Button
                color="dark"
                className="clickable --with-highlight"
                onClick={dismiss}
              >
                Done
              </Button>
            </div>
          }
        />
      </div>
      {/* </ReactWOW> */}
    </>
  );
};
