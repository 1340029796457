import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import Enums from "../constants/enums";
import API from "../services/api";
import AccountContext from "./AccountContext";

const _ = require("lodash");

interface IAuthContext {
    login: (payload: any) => Promise<any>;
    logout: () => void;
    register: (payload: any) => Promise<any>;
    isLoggedIn: boolean;
    authData: any;
    permissions: string[];
}

export const AuthContext = createContext<IAuthContext>({
    login: (payload: any) => Promise.resolve(),
    logout: () => { },
    register: (payload: any) => Promise.resolve(),
    isLoggedIn: false,
    authData: null,
    permissions: [],
});


export const AuthProvider = (props: any) => {
    const accountContext = useContext(AccountContext)
    const [authData, setAuthData] = useState<{ token: string, permissions: string[] } | null>(null);
    const permissions = useMemo(() => {
        return authData?.permissions || []
    }, [authData])

    const isLoggedIn = useMemo(() => {
        return authData?.token !== null
    }, [authData])


    useEffect(() => {
        try {
            // this initializes the authData if any has been saved to the localStorage
            setAuthData(JSON.parse(localStorage.getItem("authData") || ""))
        } catch (error: any) {
            console.log(error.message)
        }
    }, [])

    const login = (payload: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const api = new API();
                const response = await api.request("post", `auth/login`, {
                    ...payload,
                });
                setAuthData(response);
                localStorage.setItem("authData", JSON.stringify(response))
                localStorage.setItem(Enums.STORAGE_KEYS.TOKEN, response.token);
                accountContext.persist(response.user);
                resolve(response);
            } catch (error) {
                console.log("LOGIN ERROR =>", error);

                reject(error)
            }
        })
    }

    const register = (payload: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                // submit data to reg endpoint
                const api = new API();
                await api.request("post", `auth/register`, {
                    ...payload,
                });
                // if successful, automatically log the user in
                const authRes = await login(payload);
                resolve(authRes);
            } catch (error) {
                reject(error)
            }
        })
    }

    const logout = () => {
        localStorage.removeItem("authData");
        setAuthData(null)
    }

    const OTP_GEN = (len: number) => {
        var digits = "0123456789";
        let OTP = "";
        for (let i = 0; i < len; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }

    return <AuthContext.Provider value={{
        login,
        logout,
        register,
        isLoggedIn,
        authData,
        permissions
    }}>
        {props.children}
    </AuthContext.Provider>
}