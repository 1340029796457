import React from 'react';
import IdleTimer from "react-idle-timer";
import { toast, ToastContainer } from 'react-toastify';
import useSound from 'use-sound';
import errorFx from "../../assets/sounds/320181_dland_hint.mp3";
import successFx from '../../assets/sounds/success_notification_alert_A_major.mp3';
import { LogoutModal, ModalLoader } from '../misc';

import { MdCheckCircle } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';
import ActionContext from "../../contexts/ActionContext";
import SideDrawer from "../SideDrawer";

const mql = window.matchMedia(`(min-width: 800px)`);

const UserAccountNav = props => {
  const timerRef = React.createRef();
  const [playErrorFx, errorFxOptions] = useSound(errorFx);
  const [playSuccessFx, successFxOptions] = useSound(successFx);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const [sidebarDocked, setSidebarDocked] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showLogout, setShowLogout] = React.useState(false);
  const [timedOut, setTimedOut] = React.useState(false);
  const onSetSidebarOpen = open => {
    setSidebarOpen(open);
  };

  React.useEffect(() => {
    mql.addListener(mediaQueryChanged);
    if (props.sidebarDocked) setSidebarOpen(false);
  }, []);

  const mediaQueryChanged = () => {
    setSidebarDocked(mql.matches);
    setSidebarOpen(false);
  };

  const logout = () => {
    setShowLogout(false);
    setLoading(true);
    localStorage.clear();
    setTimeout(() => {
      localStorage.setItem("hasSeenNewLook", "SEEN")
      window.location = '/';
    }, 2000);
  };

  const notify = (message, options) => {
    if (errorFxOptions.isPlaying) errorFxOptions.stop();
    if (successFxOptions.isPlaying) successFxOptions.stop();
    switch (options.type) {
      case 'success':
        playSuccessFx();
        break;

      default:
        playErrorFx();
        break;
    }
    toast.dark(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const _onAction = (e) => {
    setTimedOut(false)
  }

  const _onActive = (e) => {
    setTimedOut(false)
  }

  const _onIdle = (e) => {
    notify(<span><MdCheckCircle className="mr-2" style={{ fontSize: 20, color: "green" }} />Your session has expired due to inactivity</span>, { type: "success" })
    logout()
  }

  return (
    <ActionContext.Provider
      value={{
        toggleLoader: v => setLoading(v),
        showLogout: v => setShowLogout(v),
        showNotification: (m, t) => notify(m, t),
      }}
    >
      <IdleTimer
        ref={timerRef}
        element={document}
        onActive={_onActive}
        onIdle={_onIdle}
        onAction={_onAction}
        debounce={250}
        timeout={1000 * 60 * 5}
      />
      <ToastContainer />
      <ModalLoader open={loading} />
      <LogoutModal
        open={showLogout}
        toggle={() => setShowLogout(false)}
        confirm={() => logout()}
      />
      <SideDrawer current={props.current}>
        {props.children}
      </SideDrawer>
    </ActionContext.Provider >
  );
};

export default UserAccountNav;
