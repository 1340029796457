import React from 'react';
import Enums from "../constants/enums";

export const persist = data => {
  localStorage.setItem(Enums.STORAGE_KEYS.USER, JSON.stringify(data));
};

export const getUser = callback => {
  if (callback)
    return callback(JSON.parse(localStorage.getItem(Enums.STORAGE_KEYS.USER)));
};

const AccountContext = React.createContext({
  persist: persist,
  getUser: getUser,
});

export default AccountContext;
