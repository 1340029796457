import React, { useContext, useState, useEffect } from 'react';
import ReactWOW from 'react-wow';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import {
  Loader,
  OTPInputModal,
  TransactionSuccess,
} from '../../../components/misc';
import NumberFormat from 'react-number-format';
import { BsArrowRight } from 'react-icons/bs';
import { MdCheckCircle, MdError } from 'react-icons/md';
import getSymbolFromCurrency from 'currency-symbol-map';
import Switch from 'react-switch';
import {
  createEntry,
  getEntry,
  NetworkErrorHandler,
} from '../../../utils/dataGenerator';
import ActionContext from "../../../contexts/ActionContext";
import AccountContext from "../../../contexts/AccountContext";
import Enums from "../../../constants/enums";

const _ = require('lodash');

const InternalTransfer = props => {
  const initPayload = {
    bank: Enums.APP_NAME,
    accountNumber: '',
    accountName: '',
    amount: null,
    addBeneficiary: false,
    transaction_source: 'INTERNAL_TRANSFER',
    currency: 'USD',
    isInternal: true,
  };
  const actionContext = useContext(ActionContext);
  const accountContext = useContext(AccountContext);
  const [error, setError] = useState(null);
  const [showOTP, toggleOTP] = useState(false);
  const [payload, setPayload] = useState(initPayload);
  const [account, setAccount] = useState(null);
  const [state, setState] = useState({
    isLoading: false,
    isSuccess: false,
  });

  useEffect(() => {
    accountContext.getUser(user => {
      if (!user) return (window.location = '/');
      setPayload({ ...payload, currency: user.displayCurrency || 'USD' });
    });
  });

  const proceed = e => {
    try {
      e.preventDefault();
      if (!payload.accountNumber)
        throw Error('Please enter a valid account number');
      // getAccount
      setState({ ...state, isLoading: true });
      getEntry(`users/get-account/${payload.accountNumber}`, (res, err) => {
        if (!err) {
          const response = res.data;
          const { first_name, last_name, accountNumber, _id } = response;
          setAccount({
            accountName: `${first_name} ${last_name}`,
            accountNumber,
            id: _id,
          });
        } else {
          actionContext.showNotification(
            <div className="align-center">
              <MdError style={{ fontSize: 20, color: 'red' }} />
              &nbsp;{NetworkErrorHandler(err)}
            </div>,
            { type: 'error' },
          );
          setError(NetworkErrorHandler(err));
          setTimeout(() => {
            setError(null);
          }, 1500);
        }
        setState({ ...state, isLoading: false });
      });
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{error.message}
        </div>,
        { type: 'error' },
      );
      setError(error.message);
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  const validate = e => {
    try {
      e.preventDefault();
      if (!payload.accountNumber)
        throw Error('Please enter a valid account number');
      if (payload.amount <= 0) throw Error('How much do you intend to send?');
      if (!payload.description) throw Error('Please enter a description');
      console.log('payload 1', payload);
      setPayload({
        ...payload,
        beneficiary: account.id,
        accountName: account.accountName,
        accountNumber: account.accountNumber,
      });
      toggleOTP(true);
    } catch (error) {
      actionContext.showNotification(
        <div className="align-center">
          <MdError style={{ fontSize: 20, color: 'red' }} />
          &nbsp;{error.message}
        </div>,
        { type: 'error' },
      );
      setError(error.message);
      setTimeout(() => {
        setError(null);
      }, 1500);
    }
  };

  const startTransfer = () => {
    setState({ ...state, isLoading: true });
    createEntry(`transactions/transfer`, payload, (res, err) => {
      if (!err) {
        actionContext.showNotification(
          <div className="align-center">
            <MdCheckCircle style={{ fontSize: 20, color: 'green' }} />
            &nbsp;Transfer Successful
          </div>,
          { type: 'success' },
        );
        setState({ ...state, isLoading: false, isSuccess: true });
      } else {
        actionContext.showNotification(
          <div className="align-center">
            <MdError style={{ fontSize: 20, color: 'red' }} />
            &nbsp;{NetworkErrorHandler(err)}
          </div>,
          { type: 'error' },
        );
        setError(NetworkErrorHandler(err));
        setTimeout(() => {
          setError(null);
        }, 1500);
        setState({ ...state, isLoading: false });
      }
    });
  };

  return (
    // <ReactWOW animation="fadeInRight" duration="0.5s">
    <>
      {showOTP ? (
        <OTPInputModal
          open={showOTP}
          toggle={() => toggleOTP(false)}
          successCallback={startTransfer}
        />
      ) : (
        <div />
      )}
      <div className="bg-white shadow p-3 p-md-5">
        <h5 className="orange-text mb-0 stretched-text-sm">
          Internal Transfer
        </h5>
        <Row className="mt-4">
          {!account ? (
            <Col md={6} className="mx-auto">
              <Form onSubmit={!state.isLoading && proceed} className="mt-4">
                <FormGroup>
                  <Label>Account No.</Label>
                  <InputGroup className={error ? 'animated shake' : ''}>
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale={true}
                      value={payload.accountNumber}
                      disabled={state.isLoading}
                      displayType={'input'}
                      className="form-control font-weight-medium stretched-text-xs"
                      onChange={v =>
                        setPayload({
                          ...payload,
                          accountNumber: v.target.value,
                        })
                      }
                    />
                    <Button
                      onClick={!state.isLoading && proceed}
                      className="px-3 aligner-container bg-dark clickable --with-highlight white-text"
                    >
                      Proceed&nbsp;
                      <BsArrowRight
                        style={{
                          fontSize: 20,
                        }}
                      />
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Form>
            </Col>
          ) : (
            <Col md={12}>
              {/* <ReactWOW animation="fadeIn" duration="1.5s"> */}
              <>
                {state.isSuccess ? (
                  <TransactionSuccess
                    data={payload}
                    dismiss={() => {
                      setAccount(null);
                      setPayload(initPayload);
                      setState({ ...state, isSuccess: false });
                    }}
                  />
                ) : (
                  <Form
                    onSubmit={!state.isLoading && validate}
                    className="mt-4"
                  >
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Account No.</Label>
                          <Input
                            className="stretched-text-xs"
                            value={account.accountNumber}
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Account Name</Label>
                          <Input
                            className="stretched-text-xs"
                            value={account.accountName}
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Amount</Label>
                          <InputGroup>
                            {/* <InputGroupAddon>
                              {getSymbolFromCurrency(payload.currency)}
                            </InputGroupAddon> */}
                            <NumberFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              placeholder="0.00"
                              value={payload.amount}
                              displayType={'input'}
                              className="form-control font-weight-medium align-right stretched-text-xs"
                              onChange={v =>
                                setPayload({
                                  ...payload,
                                  amount: parseFloat(v.target.value),
                                })
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={6} className="aligner-container">
                        <Label>Save Beneficiary</Label>
                        <span className="pull-right mx-3">
                          <Switch
                            disabled={state.isLoading}
                            onChange={v =>
                              setPayload({ ...payload, addBeneficiary: v })
                            }
                            checked={payload.addBeneficiary}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={16}
                            width={35}
                            handleDiameter={20}
                            onHandleColor={'#2688c9'}
                            offHandleColor={'#d8d8d8'}
                            onColor={'#ffdfd5'}
                            offColor={'#b4b3b3'}
                          />
                        </span>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Description</Label>
                          <Input
                            type="textarea"
                            className="stretched-text-xs"
                            value={payload.description}
                            onChange={e =>
                              setPayload({
                                ...payload,
                                description: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {!state.isLoading && (
                        <Col md={12} className="align-right">
                          <Button
                            type="button"
                            onClick={() => setAccount(null)}
                            color={'light'}
                            className="bg-light dark-text"
                          >
                            Back
                          </Button>
                          <Button
                            type="submit"
                            color="dark"
                            className="bg-dark white-text clickable --with-highlight"
                          >
                            Proceed&nbsp;
                            <BsArrowRight
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Form>
                )}
                {/* </ReactWOW> */}
              </>
            </Col>
          )}
        </Row>
        {state.isLoading && <Loader />}
      </div>
      {/* </ReactWOW> */}
    </>
  );
};

export default InternalTransfer;
