import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import CasesTwoToneIcon from '@mui/icons-material/CasesTwoTone';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import OtherHousesTwoToneIcon from '@mui/icons-material/OtherHousesTwoTone';
import Person3TwoToneIcon from '@mui/icons-material/Person3TwoTone';
import RequestPageTwoToneIcon from '@mui/icons-material/RequestPageTwoTone';
import SavingsTwoToneIcon from '@mui/icons-material/SavingsTwoTone';
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useNavigate } from "react-router";
import Enums from "../../constants/enums";
import AccountContext from "../../contexts/AccountContext";
import { AuthContext } from "../../contexts/AuthContext";

const NavMenu = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const navigate = useNavigate();
    const { isLoggedIn } = React.useContext(AuthContext);
    const accountContext = React.useContext(AccountContext);

    React.useImperativeHandle(ref, () => ({
        toggleDrawer: () => {
            setIsOpen(!isOpen);
        }
    }));

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };

    const menuItems = React.useMemo(() => {
        let link = "", entries = [], title = "Login to Online Banking"

        accountContext.getUser(user => {
            if (user && localStorage.getItem(Enums.STORAGE_KEYS.TOKEN) !== null) {
                link = (`/${user.type == Enums.ACCOUNT_TYPES.AH
                    ? 'account'
                    : user.type == Enums.ACCOUNT_TYPES.AO || user.type == Enums.ACCOUNT_TYPES.ROOT
                        ? 'admin'
                        : 'user'
                    }/dashboard`);
                title = "My Eccount Dashboard"
            } else {
                link = ("/login");
            }
            entries = [
                {
                    title: "Eccount Home",
                    icon: <OtherHousesTwoToneIcon />,
                    link: "/"
                },
                {
                    title,
                    link,
                    icon: <LockOpenTwoToneIcon />,
                }
            ]
        });
        return entries
    }, [isLoggedIn]);

    const menuItems1 = [
        {
            title: "About Us",
            icon: <InfoTwoToneIcon />,
            link: "/services/about-us"
        },
        {
            title: "Contact Us",
            icon: <ContactSupportTwoToneIcon />,
            link: "/contact-us"
        },
    ]
    const menuItems2 = [
        {
            title: "Private Banking Services",
            icon: <AdminPanelSettingsTwoToneIcon />,
            link: "/services/private-banking"
        },
        {
            title: "Investment Banking Services",
            icon: <CasesTwoToneIcon />,
            link: "/services/investment-banking"
        },
        {
            title: "Personal Banking Services",
            icon: <Person3TwoToneIcon />,
            link: "/services/personal-banking"
        },
        {
            title: "Deposit Banking Services",
            icon: <SavingsTwoToneIcon />,
            link: "/services/deposit-banking"
        },
        {
            title: "Online Banking Services",
            icon: <LanguageTwoToneIcon />,
            link: "/services/online-banking"
        },
        {
            title: "Online Security",
            icon: <SecurityTwoToneIcon />,
            link: "/services/online-security"
        },
        // {
        //     title: "Request Account",
        //     icon: <RequestPageTwoToneIcon />,
        //     link: "/services/account-opening"
        // },
    ]

    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor={'top'}
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                    style={{ position: "absolute", zIndex: 5000 }}
                >
                    <Box
                        sx={{ width: 'auto' }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            {menuItems.map((item, index) => (
                                <ListItem key={index} disablePadding onClick={() => navigate(item.link)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>

                        <Divider />

                        <List>
                            {menuItems2.map((item, index) => (
                                <ListItem key={index} disablePadding onClick={() => navigate(item.link)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>

                        <Divider />

                        <List>
                            {menuItems1.map((item, index) => (
                                <ListItem key={index} disablePadding onClick={() => navigate(item.link)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
})

export default NavMenu;