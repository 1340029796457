import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import NumberFormat from 'react-number-format';
import { Badge } from 'reactstrap';
import { EmptyResult, ModalLoader } from '../../../components/misc';
import Enums from "../../../constants/enums";

const _ = require('lodash');

const TransactionsTable = props => {
  const [meta] = React.useState({
    page: 1,
    pageLimit: 10,
    ...props.meta
  });
  const [state, setState] = React.useState({
    isFetching: false,
  });

  createTheme('solarized', Enums.SOLARIZED);

  const [columns] = React.useState(_.sortBy([
    {
      name: 'Type',
      selector: (row, index) => {
        return (
          <Badge
            color={row.isCredit ? 'success' : 'danger'}
            className={`capitalize py-1 ${!row.isCredit ? 'bg-orange' : ''}`}
            style={{ fontSize: 13 }}
          >
            {row.isCredit ? 'Credit' : 'Debit'}
          </Badge>
        );
      },
    },
    {
      name: 'Description',
      selector: (row, index) => {
        return (
          <span
            className="table-text stretched-text-xs"
            style={{ fontSize: 15 }}
          >
            {row.description}
          </span>
        );
      },
    },
    {
      name: 'Transaction ID',
      selector: (row, index) => {
        let tid = row._id.replace(/\D+/g, '');
        // if(tid.length < 15){
        //     console.log(row.description+" "+tid, tid.length)
        //     const appendage = Math.floor(Math.random() * (9 * Math.pow(10, 13 - tid.length)) + 10000);
        //     tid = `${tid}${appendage}`
        // }
        return (
          <span
            className="table-text stretched-text-xs"
            style={{ fontSize: 15 }}
          >
            {tid}
          </span>
        );
      },
    },
    {
      name: 'Date',
      selector: (row, index) => {
        return (
          <span
            className="table-text stretched-text-xs"
            style={{ fontSize: 15 }}
          >
            {moment(row.createdAt).format('DD/MM/YYYY hh:mm a')}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row, index) => {
        return (
          <strong
            className="table-text stretched-text-xs"
            style={{ fontSize: 15 }}
          >
            {(row.isCredit ? "" : "-")+getSymbolFromCurrency(row.currency || 'USD')}
            <NumberFormat
              thousandSeparator={true}
              fixedDecimalScale={true}
              decimalScale={2}
              value={row.amount}
              displayType={'text'}
            />
          </strong>
        );
      },
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: (row, index) => (
    //     <Button
    //       outline
    //       color="danger"
    //       size={"sm"}
    //       onClick={() => selectHistory(row)}
    //     >
    //       View
    //     </Button>
    //   ),
    //   center: true,
    // },
  ],function(col){return col.name}));

  return (
    <DataTable
      noHeader={true}
      columns={columns}
      data={props.transactions || []}
      striped={true}
      highlightOnHover={true}
      responsive={true}
      overflowY={true}
      pagination={true}
      theme={'solarized'}
      paginationTotalRows={meta.totalDocs || 0}
      paginationPerPage={meta.pageLimit}
      noRowsPerPage={false}
      progressPending={props.isLoading}
      progressComponent={<ModalLoader open={props.isLoading} />}
      noDataComponent={
        <EmptyResult text={'There are transaction records at the moment'} />
      }
      // customStyles={customStyles}
    />
  );
};
export default TransactionsTable;
